import { Box, Card, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import graph from "../../assets/svg/graph.svg";
import graphActive from "../../assets/svg/graphActive.svg";
import tableIcon from "../../assets/svg/table.svg";
import tableIconActive from "../../assets/svg/tableActive.svg";
import SubHeading from "../../commonComponents/SubHeading";
import TypographyComponent from "../../commonComponents/Typography";
import {
  EstimatedRevenueCard,
  LossCard,
  MeetCard,
  TargetCard,
  WonCard,
} from "../../components/Card";
import {
  Barchart,
  PipelineChart,
  ProgressBar,
  SparkChart,
} from "../../components/Chart/Chart";
import DatePicker from "../../components/DatePicker";
import DynamicTable from "../../components/DynamicTable";
import NoDataFound from "../../components/NoDataFound";
import { useDynamicHeight } from "../../hooks/useDynamicHeight";

const getMonthYearRange = (from, to) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const months = [];
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  while (fromDate <= toDate) {
    months.push(monthNames[fromDate.getMonth()]);
    fromDate.setMonth(fromDate.getMonth() + 1);
  }

  return months;
};

const processData = (data, months) => {
  const prospectData = new Array(months.length).fill(0);
  const clientData = new Array(months.length).fill(0);

  data.prospectVsClient?.prospectCountMonthandYearWise.forEach((item) => {
    item.month.forEach((month, index) => {
      const monthIndex = months.indexOf(
        month.charAt(0).toUpperCase() + month.slice(1)
      );
      if (monthIndex >= 0) {
        prospectData[monthIndex] = item.count[index];
      }
    });
  });

  data.prospectVsClient?.clientCountMonthandYearWise.forEach((item) => {
    item.month.forEach((month, index) => {
      const monthIndex = months.indexOf(
        month.charAt(0).toUpperCase() + month.slice(1)
      );
      if (monthIndex >= 0) {
        clientData[monthIndex] = item.count[index];
      }
    });
  });

  return { prospectData, clientData };
};

const Dashboard = () => {
  const [tabOn, setTabOn] = useState(true);
  const [target, setTarget] = useState(true);
  const [prospectData, setProspectData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [xLabels, setXLabels] = useState([]);

  const dashContent = useSelector((state) => state.dashboard?.dashData);
  const screenSize = useDynamicHeight();
  const cardHeight = screenSize.dynamicHeight * 0.743;
  const progressBarHeight = screenSize.dynamicHeight * 0.526;

  useEffect(() => {
    if (dashContent) {
      const { from, to } = dashContent;
      const months = getMonthYearRange(from, to);
      const { prospectData, clientData } = processData(dashContent, months);
      setXLabels(months);
      setProspectData(prospectData);
      setClientData(clientData);
    }
  }, [dashContent]);

  const handleTable = () => {
    setTabOn(false);
  };

  const handleTableOn = () => {
    setTabOn(true);
  };

  const handleTarget = () => {
    setTarget(false);
  };

  const handleTargetOn = () => {
    setTarget(true);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid mt={3} item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
            <DatePicker onDateChange={() => {}} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={1.9} xl={1.9}>
            <EstimatedRevenueCard revenue={dashContent} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={1.9} xl={1.9}>
            <MeetCard totalMeet={dashContent} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={1.9} xl={1.9}>
            <TargetCard target={dashContent} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={1.9} xl={1.9}>
            <WonCard won={dashContent} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={1.9} xl={1.9}>
            <LossCard lost={dashContent} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {/* Doughnut Charts */}

          <Card
            sx={{
              borderRadius: 2,
              padding: 2,
            }}
          >
            <SubHeading text={"Lead Pipeline"} color={"#000000"} />
            <Grid
              container
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              spacing={3}
            >
              <Grid item xs={12} sm={12} md={6.5} lg={6} xl={5}>
                {dashContent ? (
                  <PipelineChart funl={dashContent} />
                ) : (
                  <NoDataFound height={progressBarHeight} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5}>
                {dashContent ? (
                  <ProgressBar lin={dashContent} />
                ) : (
                  <NoDataFound />
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card
            sx={{
              px: 2,
              borderRadius: 2,
              position: "static",
              height: cardHeight,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <SubHeading text={"Prospect vs Client"} color={"#000000"} />
              <Box>
                <IconButton onClick={handleTableOn}>
                  <img src={tabOn ? graphActive : graph} alt="graph icon" />
                </IconButton>
                <IconButton onClick={handleTable}>
                  <img
                    src={tabOn ? tableIcon : tableIconActive}
                    alt="table Icon"
                  />
                </IconButton>
              </Box>
            </Box>
            {tabOn && (
              <Box
                display="flex"
                justifyContent="flex-end"
                marginY={2}
                marginRight={1.8}
              >
                <Box display="flex" alignItems="center" marginRight={2}>
                  <Box
                    width={12}
                    height={12}
                    borderRadius="50%"
                    bgcolor="#357AF6"
                    marginRight={1}
                  />
                  <TypographyComponent variant="body2">
                    Prospect
                  </TypographyComponent>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box
                    width={12}
                    height={12}
                    borderRadius="50%"
                    bgcolor="#5CC8BE"
                    marginRight={1}
                  />
                  <TypographyComponent variant="body2">
                    Client
                  </TypographyComponent>
                </Box>
              </Box>
            )}
            <Box display="flex" justifyContent="center">
              {tabOn ? (
                <Box>
                  <Barchart
                    prospectData={prospectData}
                    clientData={clientData}
                    xLabels={xLabels}
                  />
                </Box>
              ) : (
                <DynamicTable
                  prospectData={prospectData}
                  clientData={clientData}
                  xLabels={xLabels}
                  variant="prospect-client"
                />
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card sx={{ px: 2, borderRadius: 2, height: cardHeight }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <SubHeading text={" Target vs Achieved"} color={"#000000"} />
              <Box>
                <IconButton onClick={handleTargetOn}>
                  <img src={target ? graphActive : graph} alt="graph icon" />
                </IconButton>
                <IconButton onClick={handleTarget}>
                  <img
                    src={target ? tableIcon : tableIconActive}
                    alt="table Icon"
                  />
                </IconButton>
              </Box>
            </Box>
            {target && (
              <Box
                display="flex"
                justifyContent="flex-end"
                marginY={2}
                marginRight={1.8}
              >
                <Box display="flex" alignItems="center" marginRight={2}>
                  <Box
                    width={12}
                    height={12}
                    borderRadius="50%"
                    bgcolor="#357AF6"
                    marginRight={1}
                  />
                  <TypographyComponent variant="body2">
                    Target
                  </TypographyComponent>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box
                    width={12}
                    height={12}
                    borderRadius="50%"
                    bgcolor="#5CC8BE"
                    marginRight={1}
                  />
                  <TypographyComponent variant="body2">
                    Achieved
                  </TypographyComponent>
                </Box>
              </Box>
            )}
            <Box display="flex" justifyContent="center">
              {target ? (
                <SparkChart
                  prospectData={prospectData}
                  clientData={clientData}
                  xLabels={xLabels}
                />
              ) : (
                <DynamicTable
                  prospectData={prospectData}
                  clientData={clientData}
                  xLabels={xLabels}
                  variant="target-achieved"
                />
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
