import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Button } from "@mui/material";
import TableComponent from "../../components/CustomTable";
import CustomToggleButton from "../../components/ToggleButton";
import TypeSearch from "../../components/TypeSearch";
import SortAndFilter from "../../components/SortAndFilter";
import { useNavigate, useParams } from "react-router-dom";
import BulkUpload from "../../components/BulkUpload";
import addCircleIcon from "../../assets/svg/addCircleIcon.svg";
import {
  getUserStatus,
  setAddedLeadLatitude,
  setAddedLeadLongitude,
  setCompanyName,
  setCreatedDate,
  setLeadId,
  setStatus,
} from "../../redux/Slice/userStatus";
import {
  roundValue,
  COLD_BUTTON,
  HOT_BUTTON,
  TOGGLE_BUTTON,
  WARM_BUTTON,
  CapitalizeFirstLetter,
  CLIENTS_BUTTON,
  AGREEMENT_BUTTON,
} from "../../CommonMethods";
import { formatDateMonthYear } from "../../utilis/Date";
import NoDataFound from "../../components/NoDataFound";
import Loader from "../../components/Loader";

const LeadManagement = () => {
  const dispatch = useDispatch();
  const userStatus =
    useSelector((state) => state.userStatusData.userStatus) || [];
  const noDataFound = useSelector((state) => state.userStatusData.noDataFound);
  const status = useSelector((state) => state.userStatusData.status);
  const [isBulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [clearAllTrigger, setClearAllTrigger] = useState(false);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.verification?.token);
  const userId = useSelector((state) => state.verification?.user?.id);

  useEffect(() => {
    setLoading(true);
    dispatch(getUserStatus(userId, status, token)).finally(() =>
      setLoading(false)
    );
  }, [dispatch, status, token, userId, clearAllTrigger]);

  useEffect(() => {
    setFilteredData(userStatus || []);
  }, [userStatus]);

  const handleOpenBulkUpload = () => {
    setBulkUploadOpen(true);
  };

  const handleCloseBulkUpload = () => {
    setBulkUploadOpen(false);
  };

  const handleBulkUploadSuccess = () => {
    setLoading(true);
    dispatch(getUserStatus(userId, status, token)).finally(() => {
      setLoading(false);
      setBulkUploadOpen(false);
    });
  };

  const navigateAddLead = () => {
    navigate(`/home/addLead`);
  };

  const getButton = (type) => {
    switch (type) {
      case "Warm":
        return (
          <WARM_BUTTON type="button" variant="outlined">
            Warm
          </WARM_BUTTON>
        );
      case "Hot":
        return (
          <HOT_BUTTON type="button" variant="outlined">
            Hot
          </HOT_BUTTON>
        );
      case "Cold":
        return (
          <COLD_BUTTON type="button" variant="outlined">
            Cold
          </COLD_BUTTON>
        );
      case "Client":
        return (
          <CLIENTS_BUTTON type="button" variant="outlined">
            Clients
          </CLIENTS_BUTTON>
        );
      case "Clients":
        return (
          <CLIENTS_BUTTON type="button" size="small" variant="outlined">
            Clients
          </CLIENTS_BUTTON>
        );
      case "Agreement":
        return (
          <AGREEMENT_BUTTON type="button" variant="outlined">
            Agreement
          </AGREEMENT_BUTTON>
        );
      case "NA":
        return notAvailable();
      default:
        return null;
    }
  };

  const navigate = useNavigate();

  const handleTableRowClick = (e, idx, row) => {
    dispatch(setLeadId(row.id));
    dispatch(setCreatedDate(row.createdDate));
    dispatch(setCompanyName(row.companyName));
    dispatch(setAddedLeadLatitude(row.lat));
    dispatch(setAddedLeadLongitude(row.lon));
    navigate("/home/leadInfo");
    dispatch(setStatus(status));
  };

  const notAvailable = () => {
    return (
      <Box
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "16.41px",
          color: "#F28500",
          py: 0.8,
        }}
      >
        {"NA"}
      </Box>
    );
  };

  const normalizedTableData = (data) => {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.map((el) => {
      const {
        companyName,
        city,
        lastContacted,
        contactPerson,
        paySlab,
        id,
        createdDate,
        lat,
        lon,
      } = el;

      // Null checks
      const contactPersonName =
        contactPerson && contactPerson[0]
          ? CapitalizeFirstLetter(contactPerson[0]?.name)
          : notAvailable();
      const contactPersonDesignation =
        contactPerson && contactPerson[0]
          ? contactPerson[0]?.designation
          : notAvailable();
      const estimatedTopLine = paySlab?.toplineRevenue
        ? `₹ ${roundValue(paySlab?.toplineRevenue)}`
        : notAvailable();
      const estimatedRevenue =
        paySlab?.estRevenue > 0
          ? `₹ ${roundValue(paySlab?.estRevenue)}`
          : status === "active"
          ? "-"
          : notAvailable();
      const typeButton = getButton(paySlab?.type || "NA");
      const lastContactedDate =
        lastContacted === null
          ? status === "active"
            ? "-"
            : notAvailable()
          : formatDateMonthYear(lastContacted);

      return {
        "Lead Name": CapitalizeFirstLetter(companyName),
        Location: city === "NA" ? "NA" : CapitalizeFirstLetter(city),
        "Last Contacted": lastContactedDate,
        "Contact Person": contactPersonName,
        Designation: contactPersonDesignation,
        "Estimated Top Line": estimatedTopLine,
        "Estimated Revenue": estimatedRevenue,
        Type: <Box sx={{ textAlign: "center" }}>{typeButton}</Box>,
        id,
        createdDate,
        companyName,
        lat,
        lon,
      };
    });
  };

  const handleSearch = (value) => {
    if (!value) {
      setFilteredData(userStatus);
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const filteredLeadName = userStatus.filter((item) =>
      item.companyName.toLowerCase().includes(lowercasedValue)
    );
    setFilteredData(filteredLeadName);
  };

  const handleButtonClick = (value) => {
    setLoading(true);
    dispatch(setStatus(value));
  };

  return (
    <>
      <Card sx={{ p: "20px 20px 0 20px", height: "70vh" }}>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mb: 3 }}
        >
          <Button
            sx={{
              px: 9,
              border: "1.2px solid #3771C3",
              display: "flex",
              color: "#3771C3",
              textTransform: "none",
            }}
            size="small"
            variant="outlined"
            onClick={handleOpenBulkUpload}
          >
            {"Bulk Upload"}
          </Button>
          <Button
            sx={{
              px: 9,
              background: "var(--gradient1)",
              textTransform: "none",
            }}
            startIcon={<img src={addCircleIcon} alt={"Add circle icon"} />}
            size="small"
            variant="contained"
            onClick={navigateAddLead}
          >
            {"Add Lead"}
          </Button>
        </Box>
        <Box sx={{ mb: 3 }}>
          <CustomToggleButton
            defaultValue={status}
            buttons={TOGGLE_BUTTON}
            onButtonClick={handleButtonClick}
          />
        </Box>
        {status === "active" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 1,
              mb: 3,
            }}
          >
            <TypeSearch placeholder="Search by name" onSearch={handleSearch} />
            <SortAndFilter setClearAllTrigger={setClearAllTrigger} />
          </Box>
        )}
        <Box sx={{}}>
          {loading ? (
            <Loader
              height={
                status === "draft"
                  ? "52vh"
                  : status === "active"
                  ? "43vh"
                  : "0px"
              }
            />
          ) : noDataFound ? (
            <NoDataFound height={status === "active" ? 185 : 275} />
          ) : filteredData.length > 0 ? (
            <TableComponent
              isDraft={status}
              tableDataSet={filteredData}
              displayDataSet={normalizedTableData(filteredData)}
              tableOnClick={handleTableRowClick}
              loading={loading}
              isStatus={status}
            />
          ) : (
            <NoDataFound />
          )}
        </Box>
      </Card>
      <BulkUpload
        open={isBulkUploadOpen}
        onClose={handleCloseBulkUpload}
        onSuccess={handleBulkUploadSuccess}
      />
    </>
  );
};

export default LeadManagement;
