import React, { useState } from "react";
import { Box } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Menu from "../components/Menu/Menu";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import styles from "./layout.module.css";

const LayoutPage = () => {
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.verification?.token);
  const navigate = useNavigate();

  if (!token) {
    navigate("/");
  }

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.main}>
      <Navbar />
      <div className={styles.container}>
        <div className={styles.menuContainer}>
          <Menu handleDrawerToggle={handleDrawerToggle} open={open} />
        </div>
        <div
          className={`${styles.contentContainer} ${
            open
              ? styles.contentContainerExpanded
              : styles.contentContainerCollapsed
          }`}
        >
          <Box sx={{ m: 2 }}>
            <Outlet />
          </Box>
          <Box sx={{ mx: 2, mb: 2 }}>
            <Footer />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default LayoutPage;


