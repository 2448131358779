import { Card } from '@mui/material'
import React from 'react';
import styles from './footer.module.css';
const Footer = () => {
  return (
    <Card sx={{p:1.2}} className={styles.footContain} elevation={4}>
      <span>Copyright ©️ 2024 CITPL.  All rights reserved.</span>
      <span>Designed and developed by Cavin Infotech</span>
    </Card>
  )
}

export default Footer