import { Box } from "@mui/material";
import React from "react";

const NoDataFound = ({ height, text = "No data Found" }) => {
  return (
    <Box
      sx={{
        height: height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>{text}</div>
    </Box>
  );
};

export default NoDataFound;
