import { useEffect, useState } from "react";
import GoBack from "../../components/GoBackButton";
import { useDispatch, useSelector } from "react-redux";
import { getFollowUpHistory } from "../../redux/Slice/userStatus";
import { Box, Grid, Divider, Card } from "@mui/material";
import styled from "@mui/system/styled";
import arrowHorizontalIcon from "../../assets/svg/arrowHorizontal.svg";
import distanceIcon from "../../assets/svg/distanceHistory.svg";
import dateRoundIcon from "../../assets/svg/historyRoundIcon.svg";
import timeIcon from "../../assets/svg/timeLight.svg";
import { formatDateMonthYear, formatMonthYear } from "../../utilis/Date";
import NoDataFound from "../../components/NoDataFound";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import {
  COMPLETED,
  RESCHEDULED,
  CANCELLED,
  filteredName,
} from "../../CommonMethods";
import Loader from "../../components/Loader";
import { useDynamicHeight } from "../../hooks/useDynamicHeight";
import { convertDateTimeIst } from "../../utilis/TimeZone";

const FollowupHistory = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const followUpHistory = useSelector(
    (state) => state?.userStatusData?.followUpHistory
  );
  const token = useSelector((state) => state.verification?.token);
  const leadId = useSelector((state) => state?.userStatusData.leadId);

  useEffect(() => {
    dispatch(getFollowUpHistory(leadId, token)).finally(() =>
      setLoading(false)
    );
  }, [dispatch, token, leadId]);

  const getButton = (type) => {
    switch (type) {
      case "Completed":
        return (
          <COMPLETED type="button" size="small" variant="outlined">
            COMPLETED
          </COMPLETED>
        );
      case "Rescheduled":
        return (
          <RESCHEDULED type="button" size="small" variant="outlined">
            RESCHEDULED
          </RESCHEDULED>
        );
      case "Cancelled":
        return (
          <CANCELLED type="button" size="small" variant="outlined">
            CANCELLED
          </CANCELLED>
        );
      case null:
        return "";
      default:
        return null;
    }
  };

  const Item = styled("div")(({ theme }) => ({
    border: "1.4px solid #204d89",
    padding: theme.spacing(1),
    borderRadius: "8px",
    textAlign: "center",
    lineHeight: 3,
  }));

  const groupByMonthYear = (data) => {
    return data.reduce((acc, item) => {
      const monthYear = formatMonthYear(item.date);
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(item);
      return acc;
    }, {});
  };

  const groupedHistory = groupByMonthYear(followUpHistory);
  const screenSize = useDynamicHeight();
  const height = screenSize.dynamicHeight * 0.736;

  return (
    <Card
      className={"scrollbar-hidden"}
      sx={{
        backgroundColor: "#ffffff",
        height: height,
        overflow: "auto",
      }}
    >
      <GoBack heading={`Follow-Ups History`} />
      <br />
      {loading ? (
        <Loader height={"62.3vh"} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={16}>
            {followUpHistory && followUpHistory.length > 0 ? (
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {Object.keys(groupedHistory).map((monthYear, index) => (
                  <div key={index}>
                    {index > 0 && (
                      <Divider
                        sx={{
                          my: 2,
                          borderBottomWidth: 1.5,
                          borderBottomStyle: "solid",
                        }}
                      />
                    )}
                    <TimelineItem sx={{ ml: 6 }}>
                      <TimelineSeparator sx={{ mt: 10, mb: 5 }}>
                        <TimelineDot
                          sx={{
                            backgroundColor: "#597ba7",
                            position: "absolute",
                            top: 53,
                            left: -4,
                          }}
                        />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Grid sx={{ ml: 4.5 }} item xs={16}>
                          <TimelineContent
                            sx={{
                              color: "#3771C3",
                              fontSize: 16,
                              fontWeight: 600,
                              position: "absolute",
                              left: -68,
                              top: 16.6,
                            }}
                          >
                            {monthYear}
                          </TimelineContent>
                          {groupedHistory[monthYear].map((item) => {
                            const {
                              id,
                              date,
                              startTime,
                              endTime,
                              location,
                              type,
                            } = item;
                            return (
                              <Box key={id} sx={{ m: 2.5 }}>
                                <Grid container columns={16}>
                                  <Grid item xs={16}>
                                    <Item>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={dateRoundIcon}
                                            alt={"date rounded icon"}
                                          />
                                          <Box sx={{ px: 2 }}>
                                            {formatDateMonthYear(date)}
                                          </Box>
                                        </Box>
                                        <Box>
                                          {/* {getButton(type)} */}
                                          <COMPLETED
                                            type="button"
                                            size="small"
                                            variant="outlined"
                                          >
                                            COMPLETED
                                          </COMPLETED>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          fontSize: 14.4,
                                          fontWeight: 400,
                                          color: "var(--secondary)",
                                        }}
                                      >
                                        <img
                                          src={timeIcon}
                                          alt={"date rounded icon"}
                                        />
                                        <Box
                                          sx={{
                                            px: 2,
                                            ml: -0.6,
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1.5,
                                          }}
                                        >
                                          Started time :{" "}
                                          {convertDateTimeIst(startTime)}{" "}
                                          <img
                                            src={arrowHorizontalIcon}
                                            alt={"Arrow icon"}
                                          />{" "}
                                          Ended time :{" "}
                                          {convertDateTimeIst(endTime)}
                                        </Box>
                                      </Box>
                                      {location && (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: 14.4,
                                            fontWeight: 400,
                                            color: "var(--secondary)",
                                          }}
                                        >
                                          <Box sx={{ ml: 0.5 }}>
                                            <img
                                              src={distanceIcon}
                                              alt={"date rounded icon"}
                                            />
                                          </Box>
                                          <Box sx={{ px: 2 }}>
                                            Location : {location}
                                          </Box>
                                        </Box>
                                      )}
                                    </Item>
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          })}
                        </Grid>
                        <TimelineSeparator
                          sx={{ position: "absolute", left: -5, bottom: 14 }}
                        >
                          <TimelineDot sx={{ backgroundColor: "#597ba7" }} />
                        </TimelineSeparator>
                      </TimelineContent>
                    </TimelineItem>
                  </div>
                ))}
              </Timeline>
            ) : (
              <NoDataFound height={"62.8vh"} />
            )}
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default FollowupHistory;
