import axios from "axios";

// const baseDev = `https://cedev.ckdigital.in/api`;
// const baseQa = `https://ceqa.ckdigital.in/api`;
// const ceUat = `https://ceuat.ckdigital.in/api`;
const ceProd = `https://backend-ce.budgie.co.in/api`;

const axiosPrivateCall = axios.create({ baseURL: ceProd });

axiosPrivateCall.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

const dev = `https://globalauthdev.ckdigital.in/api/oauth2/Global/azure?state=ContracteDev`;
const qa = `https://globalauthdev.ckdigital.in/api/oauth2/Global/azure?state=Contracte`;
const local = `https://globalauthdev.ckdigital.in/api/oauth2/Global/azure?state=Contractel`;
const uat = `https://globalauthdev.ckdigital.in/api/oauth2/Global/azure?state=ContracteUAT`;
const prod = `https://globalauthorization.cavininfotech.com/api/oauth2/Global/azure?state=ContractePROD`;

export { axiosPrivateCall, dev, qa, local, uat, prod };
