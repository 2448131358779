import React, { useEffect } from "react";

function MyLocation({ onLocationAllowed }) {
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          onLocationAllowed(true);
        },
        (error) => {
          console.error("Error getting location:", error);
          onLocationAllowed(false);
        }
      );
    } else {
      console.log("Geolocation is not available in your browser.");
      onLocationAllowed(false);
    }
  }, [onLocationAllowed]);

  return null;
}

export default MyLocation;
