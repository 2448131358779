import * as Yup from "yup";

export const leadSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  industry: Yup.string().required("Industry is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  contactPerson: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Contact name is required"),
      contact: Yup.string()
        .matches(/^\d{10}$/, "Contact must be exactly 10 digits")
        .required("Contact is required"),
      mailId: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      designation: Yup.string().required("Designation is required"),
    })
  ),
  paySlab: Yup.object().shape({
    type: Yup.string().required("Type is required"),
    signupMonth: Yup.string().required("Signup month is required"),
    avgSalary: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
      .required("Average salary is required"),
    existingNumber: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
      .required("Existing number is required"),
    sourcing: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
      .required("Sourcing is required"),
    transfer: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
      .required("Transfer is required"),
    totplannedndNum: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
      .required("Total planned number is required"),
    // onetimeFee: Yup.number()
    //   .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
    //   .required("One-time fee is required"),
    otfamtType: Yup.string().required("One-time fee amount type is required"),
    onetimeRevenue: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
      .required("One-time revenue is required"),
    monthlyFee: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
      .required("Monthly fee is required"),
    mfamtType: Yup.string().required("Monthly fee amount type is required"),
    calAmt: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
      .required("Calculated amount is required"),
    paymentTerms: Yup.string().required("Payment terms are required"),
    // creditPeriod: Yup.string().required("Credit period is required"),
    // creditLimit: Yup.string().required("Credit limit is required"),
    salRevenue: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
      .required("Salary revenue is required"),
    payrollRevenue: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
      .required("Payroll revenue is required"),
    toplineRevenue: Yup.number()
      .transform((value, originalValue) => (originalValue.trim() === "" ? undefined : value))
      .required("Top line revenue is required"),
    // notes: Yup.string().required("Notes are required"),
  }),
  // followUp: Yup.array().of(
  //   Yup.object().shape({
  //     date: Yup.string().required("Date is required"),
  //     startTime: Yup.string().required("Start time is required"),
  //     endTime: Yup.string().required("End time is required"),
  //     location: Yup.string().required("Location is required"),
  //     virtualMeet: Yup.boolean(),
  //     mobileNum: Yup.string().matches(
  //       /^\d{10}$/,
  //       "Mobile number must be exactly 10 digits"
  //     ),
  //   })
  // ),
});


































// import * as Yup from "yup";

//  export const leadSchema = Yup.object().shape({
//   companyName: Yup.string().required("Company name is required"),
//   industry: Yup.string().required("Industry is required"),
//   state: Yup.string().required("State is required"),
//   city: Yup.string().required("City is required"),
//   contactPerson: Yup.array().of(
//     Yup.object().shape({
//       name: Yup.string().required("Contact name is required"),
//       contact: Yup.string()
//         .matches(/^\d{10}$/, "Contact must be exactly 10 digits")
//         .required("Contact is required"),
//       mailId: Yup.string()
//         .email("Invalid email format")
//         .required("Email is required"),
//       designation: Yup.string().required("Designation is required"),
//     })
//   ),
//   paySlab: Yup.object().shape({
//     type: Yup.string().required("Type is required"),
//     signupMonth: Yup.string().required("Signup month is required"),
//     avgSalary: Yup.number().required("Average salary is required"),
//     existingNumber: Yup.number().required("Existing number is required"),
//     sourcing: Yup.number().required("Sourcing is required"),
//     transfer: Yup.number().required("Transfer is required"),
//     totplannedndNum: Yup.number().required("Total planned number is required"),
//     onetimeFee: Yup.number().required("One-time fee is required"),
//     otfamtType: Yup.string().required("One-time fee amount type is required"),
//     onetimeRevenue: Yup.number().required("One-time revenue is required"),
//     monthlyFee: Yup.number().required("Monthly fee is required"),
//     mfamtType: Yup.string().required("Monthly fee amount type is required"),
//     calAmt: Yup.number().required("Calculated amount is required"),
//     paymentTerms: Yup.string().required("Payment terms are required"),
//     creditPeriod: Yup.string().required("Credit period is required"),
//     creditLimit: Yup.string().required("Credit limit is required"),
//     salRevenue: Yup.number().required("Salary revenue is required"),
//     payrollRevenue: Yup.number().required("Payroll revenue is required"),
//     toplineRevenue: Yup.number().required("Top line revenue is required"),
//     notes: Yup.string().required("Notes are required"),
//   }),
//   followUp: Yup.array().of(
//     Yup.object().shape({
//       date: Yup.string().required("Date is required"),
//       startTime: Yup.string().required("Start time is required"),
//       endTime: Yup.string().required("End time is required"),
//       location: Yup.string().required("Location is required"),
//       virtualMeet: Yup.boolean(),
//       mobileNum: Yup.string().matches(
//         /^\d{10}$/,
//         "Mobile number must be exactly 10 digits"
//       ),
//     })
//   ),
// });

