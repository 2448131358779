import { Box, Card, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Add from "../../assets/svg/add.svg";
import feeIcon from "../../assets/svg/fee.svg";
import logAct from "../../assets/svg/logAct.svg";
import Button from "../../commonComponents/Button";
import Dropdown from "../../commonComponents/DropDown";
import HorizontalLine from "../../commonComponents/HorizontalLine";
import ImageUpload from "../../commonComponents/ImageUpload";
import Input from "../../commonComponents/Input";
import RadioButton from "../../commonComponents/RadioInput";
import ShowToast from "../../commonComponents/ShowToast";
import SubHeading from "../../commonComponents/SubHeading";
import { useDynamicHeight } from "../../hooks/useDynamicHeight";
import useSnackbar from "../../hooks/useSnackbar";
import {
  updateLeadInfo,
  getIndustryType,
  getLeadType,
  getStateAndCity,
  getFollowUpLead,
  getLeadData,
  setStatus,
} from "../../redux/Slice/userStatus";
import { leadSchema } from "../../utilis/Validation";
import "./index.css";
import Loader from "../../components/Loader";
import NoDataFound from "../../components/NoDataFound";
import GoBack from "../../components/GoBackButton";
import MonthYearPicker from "../../commonComponents/MonthYearPicker";
import axios from "axios";
import dayjs from "dayjs";

const FollowupCards = lazy(() =>
  import("../../components/FollowupCards/FollowupCards")
);
const AddFollowupModal = lazy(() =>
  import("../../components/AddFollowUpModal")
);

const EditLeadInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.verification?.user.id);
  const {
    industry,
    stateAndCity,
    leadType,
    leadId,
    leadData,
    followUpData,
    status,
    addedLeadLatitude,
    addedLeadLongitude,
  } = useSelector((state) => state?.userStatusData);
  const [selectedImages, setSelectedImages] = useState(leadData?.images || []);
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [removeImageIds, setRemoveImageIds] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({
    latitude: null,
    longitude: null,
  });
  const [address, setAddress] = useState(null);

  const [updateLead, setUpdateLead] = useState({
    userId: leadData?.userId || userId,
    companyName: leadData?.companyName || "",
    industry: leadData?.industry || "",
    state: leadData?.state || "",
    city: leadData?.city || "",
    location: address || "",
    lat: addedLeadLatitude || "",
    lon: addedLeadLongitude || "",
    coords: {
      lat: currentPosition.latitude || "",
      long: currentPosition.longitude || "",
    },
    contactPerson: leadData?.contactPerson || [
      {
        id: 1,
        name: "",
        contact: "",
        mailId: "",
        designation: "",
      },
    ],
    paySlab: {
      type: leadData?.paySlab?.type || "",
      signupMonth: leadData?.paySlab?.signupMonth || "",
      avgSalary: leadData?.paySlab?.avgSalary || "",
      existingNumber: leadData?.paySlab?.existingNumber || "",
      sourcing: leadData?.paySlab?.sourcing || "",
      transfer: leadData?.paySlab?.transfer || "",
      totplannedndNum: leadData?.paySlab?.totplannedndNum || "",
      onetimeFee: leadData?.paySlab?.onetimeFee || "",
      otfamtType: leadData?.paySlab?.otfamtType || "Flat amount",
      onetimeRevenue: leadData?.paySlab?.onetimeRevenue || "",
      monthlyFee: leadData?.paySlab?.monthlyFee || "",
      mfamtType: leadData?.paySlab?.mfamtType || "Flat amount",
      calAmt: leadData?.paySlab?.calAmt || "",
      paymentTerms: leadData?.paySlab?.paymentTerms || "Collect & Pay",
      creditPeriod: leadData?.paySlab?.creditPeriod || "",
      creditLimit: leadData?.paySlab?.creditLimit || "",
      salRevenue: leadData?.paySlab?.salRevenue || "",
      payrollRevenue: leadData?.paySlab?.payrollRevenue || "",
      toplineRevenue: leadData?.paySlab?.toplineRevenue || "",
      notes: leadData?.paySlab?.notes || "",
    },
    status: leadData?.status || "active",
  });

  const [originalContactPersons, setOriginalContactPersons] = useState([]);

  const {
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity = "success",
    showSnackbar,
    handleCloseSnackbar,
  } = useSnackbar();

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const { latitude, longitude } = position.coords;
        setCurrentPosition({ latitude, longitude });
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_LOCATION_API_KEY}`;

        axios
          .get(geocodeUrl)
          .then((response) => {
            if (response.data.results.length > 0) {
              setAddress(response.data.results[0].formatted_address);
              setLoading(false);
            } else {
              console.error("No address found for these coordinates.");
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error fetching address:", error);
          });
      });
    } else {
      setLoading(false);
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  useEffect(() => {
    if (currentPosition.latitude && currentPosition.longitude && address) {
      setUpdateLead((prevState) => ({
        ...prevState,
        location: address,
        coords: {
          lat: currentPosition.latitude || "",
          long: currentPosition.longitude || "",
        },
      }));
    }
  }, [currentPosition, address]);

  useEffect(() => {
    dispatch(getIndustryType());
    dispatch(getStateAndCity());
    dispatch(getLeadType());
    // Save original contact persons
    setOriginalContactPersons(leadData?.contactPerson || []);
  }, [dispatch]);

  // Fetch individual lead and follow-up data
  const getIndividualLead = useCallback(
    async (id) => {
      setLoading(true);
      try {
        dispatch(getFollowUpLead(id));
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );

  const getLeads = useCallback(async () => {
    try {
      dispatch(getLeadData(leadId));
      if (leadData.id) {
        getIndividualLead(leadData.id);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  }, [leadId, getIndividualLead, dispatch]);

  useEffect(() => {
    getLeads();
  }, [leadId, getLeads]);

  // Sync selectedImages with leadData.images whenever leadData changes
  useEffect(() => {
    if (leadData?.images) {
      setSelectedImages(leadData.images);
    }
  }, [leadData]);

  useEffect(() => {
    if (refresh > 0) {
      if (leadData.id) {
        getIndividualLead(leadData.id);
      }
    }
  }, [refresh, leadData.id, getIndividualLead]);

  useEffect(() => {
    const {
      avgSalary,
      sourcing,
      transfer,
      onetimeFee,
      otfamtType,
      monthlyFee,
      mfamtType,
    } = updateLead?.paySlab;

    const averageSalary = parseFloat(avgSalary) || 0;
    const plannedSourcingNumber = parseFloat(sourcing) || 0;
    const plannedTransferNumber = parseFloat(transfer) || 0;

    // Calculate totalPlannedNumber correctly by summing sourcing and transfer
    const totalPlannedNumber = plannedSourcingNumber + plannedTransferNumber;

    setUpdateLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        totplannedndNum: totalPlannedNumber.toString(),
      },
    }));
    const onetimeFeeValue = parseFloat(onetimeFee) || 0;
    const monthlyFeeValue = parseFloat(monthlyFee) || 0;

    // Salary revenue calculation
    const salaryRevenue = averageSalary * totalPlannedNumber;

    // Payroll revenue calculation
    let payrollRevenue = 0;
    if (mfamtType === "Percentage") {
      payrollRevenue = (salaryRevenue * monthlyFeeValue) / 100;
    } else {
      // payrollRevenue =
      //   0.05 * averageSalary * (plannedSourcingNumber + plannedTransferNumber);
      payrollRevenue = monthlyFeeValue * totalPlannedNumber;
    }

    // One time revenue calculation
    let oneTimeRevenue = 0;
    if (otfamtType === "Percentage") {
      oneTimeRevenue =
        averageSalary * 12 * plannedSourcingNumber * (onetimeFeeValue / 100);
    } else {
      oneTimeRevenue = onetimeFeeValue * plannedSourcingNumber;
    }

    // Top line revenue calculation
    let topLineRevenue = salaryRevenue + payrollRevenue;
    if (plannedSourcingNumber > 0) {
      topLineRevenue += oneTimeRevenue;
    }

    // Monthly revenue calculation
    let monthlyRevenue = 0;
    if (mfamtType === "Flat amount") {
      monthlyRevenue = totalPlannedNumber * monthlyFeeValue;
    } else {
      monthlyRevenue =
        averageSalary * totalPlannedNumber * (monthlyFeeValue / 100);
    }

    setUpdateLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        salRevenue: salaryRevenue.toFixed(2),
        payrollRevenue: payrollRevenue.toFixed(2),
        toplineRevenue: topLineRevenue.toFixed(2),
        onetimeRevenue: oneTimeRevenue.toFixed(2),
        calAmt: monthlyRevenue.toFixed(2),
      },
    }));
  }, [
    updateLead.paySlab.avgSalary,
    updateLead.paySlab.existingNumber,
    updateLead.paySlab.sourcing,
    updateLead.paySlab.transfer,
    updateLead.paySlab.totplannedndNum,
    updateLead.paySlab.onetimeFee,
    updateLead.paySlab.otfamtType,
    updateLead.paySlab.monthlyFee,
    updateLead.paySlab.mfamtType,
  ]);

  const handleAddContact = () => {
    if (updateLead?.contactPerson.length < 10) {
      const newContacts = JSON.parse(JSON.stringify(updateLead.contactPerson)); // Create a deep copy
      const nextId = newContacts.length + 1;
      newContacts.push({
        id: nextId,
        name: "",
        contact: "",
        mailId: "",
        designation: "",
      });
      setUpdateLead((prevState) => ({
        ...prevState,
        contactPerson: newContacts,
      }));
    } else {
      showSnackbar("You cannot add more than 10 secondary contacts.");
    }
  };

  const handleContactChange = (index, field, value) => {
    const newContacts = JSON.parse(JSON.stringify(updateLead.contactPerson)); // Create a deep copy
    newContacts[index][field] = value;
    setUpdateLead((prevState) => ({
      ...prevState,
      contactPerson: newContacts,
    }));
  };

  const handleCompanyNameChange = (event) => {
    setUpdateLead((prevState) => ({
      ...prevState,
      companyName: event.target.value,
    }));
  };

  const handleIndustryChange = (newValue) => {
    setUpdateLead((prevState) => ({
      ...prevState,
      industry: newValue,
    }));
  };

  const handleStateChange = (newValue) => {
    setUpdateLead((prevState) => ({
      ...prevState,
      state: newValue,
    }));
  };

  const handleCityChange = (newValue) => {
    setUpdateLead((prevState) => ({
      ...prevState,
      city: newValue,
    }));
  };

  const handleTypeChange = (newValue) => {
    setUpdateLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        type: newValue,
      },
    }));
  };

  const handleSignUpMonthChange = (newValue) => {
    const formattedDate = newValue ? dayjs(newValue).format("MMMM YYYY") : "";
    setUpdateLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        signupMonth: formattedDate,
      },
    }));
  };

  const handleOneTimeFeeRadioButtonChange = (event) => {
    setUpdateLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        otfamtType: event.target.value,
      },
    }));
  };

  const handleMonthlyFeeRadioButtonChange = (event) => {
    setUpdateLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        mfamtType: event.target.value,
      },
    }));
  };

  const handleCollectAndPayChange = (event) => {
    setUpdateLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        paymentTerms: event.target.value,
      },
    }));
  };

  const handleRemoveImage = (image) => {
    if (image.id) {
      setRemoveImageIds((prevIds) => [...prevIds, image.id]);
    }
  };

  const handleUpdateLeadSubmit = async () => {
    try {
      await leadSchema.validate(updateLead, { abortEarly: false });
      const leadData = JSON.parse(JSON.stringify(updateLead));

      // Remove the ID for newly added contacts (where ID is a number)
      leadData.contactPerson = leadData.contactPerson.map((contact) => {
        if (typeof contact.id === "number") {
          const { id, ...rest } = contact;
          return rest;
        }
        return contact;
      });

      const isDraftToActive = updateLead.status === "draft";

      leadData.status = "active";

      await dispatch(
        updateLeadInfo(leadId, selectedImages, leadData, removeImageIds)
      );

      setSelectedImages(leadData.images || []);
      // If moving from draft to active, navigate to leadManagement and set status
      if (isDraftToActive) {
        showSnackbar(
          "Status successfully moved from Draft to Active",
          "success"
        );
        setTimeout(() => {
          dispatch(setStatus("active"));
          navigate("/home/leadManagement", { replace: true });
        }, 500);
      } else {
        showSnackbar("Lead updated successfully", "success");
        setTimeout(() => {
          navigate("/home/leadInfo", {
            replace: true,
            state: { from: "/home/leadManagement" },
          });
        }, 500);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.reverse().forEach((error) => {
          showSnackbar(error.message);
        });
      }
    }
  };

  const handleUpdateSaveDraft = async () => {
    try {
      // Validate only the company name for saving as a draft
      await Yup.object()
        .shape({
          companyName: Yup.string().required("Company name is required"),
        })
        .validate(updateLead, { abortEarly: false });

      const leadData = JSON.parse(JSON.stringify(updateLead));

      // Remove the ID for newly added contacts (where ID is a number)
      leadData.contactPerson = leadData.contactPerson.map((contact) => {
        if (typeof contact.id === "number") {
          const { id, ...rest } = contact;
          return rest;
        }
        return contact;
      });

      // Check if all other required fields are filled (for active status)
      const allFieldsFilled = leadSchema.isValidSync(updateLead);

      if (allFieldsFilled) {
        // If all fields are filled, update to active status
        await handleUpdateLeadSubmit(); // Move lead to active status
      } else {
        // Otherwise, save as draft
        leadData.status = "draft";

        await dispatch(
          updateLeadInfo(leadId, selectedImages, leadData, removeImageIds)
        );
        // After updating, sync selectedImages with the updated leadData from Redux
        setSelectedImages(leadData.images || []);
        showSnackbar("Draft updated successfully", "success");
        setTimeout(() => {
          navigate("/home/leadInfo", {
            replace: true,
            state: { from: "/home/leadManagement" },
          });
        }, 500);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.reverse().forEach((error) => {
          showSnackbar(error.message);
        });
      }
    }
  };

  const handleLogActivityClick = () => {
    navigate("/home/logActivity");
  };
  const screenSize = useDynamicHeight();
  const height = screenSize.dynamicHeight * 0.736;

  return (
    <Card
      className={"scrollbar-hidden"}
      sx={{
        backgroundColor: "#ffffff",
        height: height,
        overflow: "auto",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <GoBack heading={leadData?.companyName} />

        {status === "active" && (
          <Box sx={{ mr: 1.8, display: "flex", alignItems: "center" }}>
            <img src={logAct} alt="log-activity-img" loading="lazy" />
            <span
              className={"custom-underline pointer"}
              style={{ marginLeft: "4px", color: "#3771C3" }}
              onClick={handleLogActivityClick}
            >
              Log Activity
            </span>
          </Box>
        )}
      </Box>
      {loading ? (
        <Loader height={"63.2vh"} />
      ) : (
        <Box sx={{ m: 2, lineHeight: 2.5 }}>
          {/**Lead Information */}
          <Box>
            <SubHeading text={"Edit Lead Information"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                disabled
                label={"Company Name"}
                placeholder={"Enter Company Name"}
                showAsterisk={true}
                onChange={handleCompanyNameChange}
                value={updateLead?.companyName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Dropdown
                label={"Industry"}
                placeholder={"Choose industry type"}
                value={updateLead?.industry}
                onChange={handleIndustryChange}
                options={industry.map((ind) => ({
                  value: ind.label,
                  label: ind.label,
                }))}
                showAsterisk={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Dropdown
                label={"State"}
                placeholder={"Select State"}
                value={updateLead?.state}
                onChange={handleStateChange}
                options={stateAndCity.map((state) => ({
                  value: state.name,
                  label: state.name,
                }))}
                showAsterisk={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Dropdown
                label={"City"}
                placeholder={"Select City"}
                value={updateLead?.city}
                onChange={handleCityChange}
                options={
                  updateLead?.state
                    ? stateAndCity
                        .find((state) => state.name === updateLead?.state)
                        ?.districts.map((city) => ({
                          value: city,
                          label: city,
                        }))
                    : []
                }
                showAsterisk={true}
              />
            </Grid>
          </Grid>
          <ImageUpload
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            onRemoveImage={handleRemoveImage}
          />
          {/**Contact Person */}
          <Box>
            <SubHeading marginTop={1.5} text={"Edit Contact Person"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          {/**Primary Contact */}
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: 500,
              color: " #505259",
            }}
          >
            {"Primary Contact"}
          </Typography>
          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"text"}
                label={"Name"}
                placeholder={"Enter Name"}
                showAsterisk={true}
                value={updateLead?.contactPerson[0].name}
                onChange={(e) => handleContactChange(0, "name", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"mobileNumber"}
                label={"Contact"}
                placeholder={"Enter Contact"}
                showAsterisk={true}
                value={updateLead?.contactPerson[0].contact}
                onChange={(e) =>
                  handleContactChange(0, "contact", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"emai"}
                label={"Mail ID"}
                placeholder={"Enter Mail ID"}
                showAsterisk={true}
                value={updateLead?.contactPerson[0].mailId}
                onChange={(e) =>
                  handleContactChange(0, "mailId", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"text"}
                label={"Designation "}
                placeholder={"Enter Designation"}
                showAsterisk={true}
                value={updateLead?.contactPerson[0].designation}
                onChange={(e) =>
                  handleContactChange(0, "designation", e.target.value)
                }
              />
            </Grid>
          </Grid>
          {/**Secondary Contact */}
          {updateLead?.contactPerson &&
            updateLead?.contactPerson.length > 0 &&
            updateLead?.contactPerson.slice(1).map((contact, index) => (
              <React.Fragment key={contact.id}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: " #505259",
                    marginTop: index > 0 ? "16px" : "0",
                  }}
                >
                  {`Secondary Contact ${index + 1}`}
                </Typography>
                <Grid container spacing={1.8}>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Input
                      type={"text"}
                      label={"Name"}
                      placeholder={"Enter Name"}
                      showAsterisk={true}
                      value={contact.name}
                      onChange={(e) =>
                        handleContactChange(index + 1, "name", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Input
                      type={"mobileNumber"}
                      label={"Contact"}
                      placeholder={"Enter Contact"}
                      showAsterisk={true}
                      value={contact.contact}
                      onChange={(e) =>
                        handleContactChange(
                          index + 1,
                          "contact",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Input
                      label={"Mail Id"}
                      placeholder={"Enter Mail Id"}
                      showAsterisk={true}
                      value={contact.mailId}
                      onChange={(e) =>
                        handleContactChange(index + 1, "mailId", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Input
                      type={"text"}
                      label={"Designation"}
                      placeholder={"Enter Designation"}
                      showAsterisk={true}
                      value={contact.designation}
                      onChange={(e) =>
                        handleContactChange(
                          index + 1,
                          "designation",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}

          <Button
            text={"Add Contact"}
            startIcon={<img src={Add} alt={"Add icon"} />}
            color={"#0e3973"}
            borderColor={"#0e3973"}
            variant={"outlined"}
            onClick={handleAddContact}
            // disabled={contacts.length >= 10}
          />
          <ShowToast
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          />
          {/**Lead Status */}
          <Box>
            <SubHeading marginTop={1.5} text={"Edit Lead Status"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Dropdown
                label={"Type"}
                placeholder={"Choose Type"}
                value={updateLead?.paySlab?.type}
                onChange={handleTypeChange}
                options={leadType.map((lead) => ({
                  value: lead.name,
                  label: lead.name,
                }))}
                showAsterisk={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <MonthYearPicker
                label="Sign up month"
                showAsterisk
                value={updateLead?.paySlab?.signupMonth}
                onChange={handleSignUpMonthChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"decimal"}
                label={"Average Salary"}
                placeholder={"Enter Average Salary"}
                value={updateLead?.paySlab?.avgSalary}
                onChange={(e) =>
                  setUpdateLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      avgSalary: e.target.value,
                    },
                  }))
                }
                showAsterisk={true}
              />
            </Grid>
          </Grid>
          {/**Existing Number */}
          <Box>
            <SubHeading marginTop={1.5} text={"Edit Existing Number"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"decimal"}
                label={"Existing Number"}
                placeholder={"Enter Existing Number"}
                value={updateLead?.paySlab?.existingNumber}
                onChange={(e) =>
                  setUpdateLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      existingNumber: e.target.value,
                    },
                  }))
                }
                showAsterisk={true}
              />
            </Grid>
          </Grid>
          {/**Planned Number */}
          <Box>
            <SubHeading
              marginTop={1.5}
              text={"Edit Planned Number"}
              subText={"(Any one required)"}
            />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          <Box>
            <Grid container spacing={1.8}>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <Input
                  type={"decimal"}
                  label={"Sourcing"}
                  placeholder={"Enter Sourcing"}
                  value={updateLead?.paySlab?.sourcing}
                  onChange={(e) =>
                    setUpdateLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        sourcing: e.target.value,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <Input
                  type={"decimal"}
                  label={"Transfer"}
                  placeholder={"Enter Transfer"}
                  value={updateLead?.paySlab?.transfer}
                  onChange={(e) =>
                    setUpdateLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        transfer: e.target.value,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={6}
                lg={6}
                xl={6}
                display={"flex"}
                justifyContent={"end"}
              >
                <Input
                  type={"number"}
                  startIcon={"₹"}
                  disabled={true}
                  label={"Total Number"}
                  placeholder={"800"}
                  value={updateLead?.paySlab?.totplannedndNum}
                  onChange={(e) =>
                    setUpdateLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        totplannedndNum: e.target.value,
                      },
                    }))
                  }
                  backgroundColor={"#F6F6F6"}
                />
              </Grid>
            </Grid>
          </Box>
          {/**Sourcing */}
          <Box>
            <SubHeading marginTop={1.5} text={"Edit Sourcing"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={1.8} alignItems={"center"}>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Input
                  type={
                    updateLead?.paySlab?.otfamtType === "Flat amount"
                      ? "decimal"
                      : "number"
                  }
                  label={"One time fee"}
                  placeholder={"Enter Amount"}
                  value={updateLead?.paySlab?.onetimeFee}
                  maxLength={
                    updateLead?.paySlab?.otfamtType === "Flat amount" ? "" : 5
                  }
                  onChange={(e) =>
                    setUpdateLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        onetimeFee: e.target.value,
                      },
                    }))
                  }
                  endIcon={<img src={feeIcon} alt="fee icon" />}
                />
              </Grid>
              <Grid marginTop={1.5} item xs={6} sm={3} md={2} lg={1.5} xl={1}>
                <RadioButton
                  label={"Flat amount"}
                  value={"Flat amount"}
                  onChange={handleOneTimeFeeRadioButtonChange}
                  checked={updateLead?.paySlab?.otfamtType === "Flat amount"}
                />
              </Grid>
              <Grid marginTop={1.5} item xs={6} sm={3} md={2} lg={1.5} xl={1}>
                <RadioButton
                  label="Percentage"
                  value={"Percentage"}
                  onChange={handleOneTimeFeeRadioButtonChange}
                  checked={updateLead?.paySlab?.otfamtType === "Percentage"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={5}
                lg={6}
                xl={7}
                display={"flex"}
                justifyContent={"end"}
              >
                <Input
                  disabled={true}
                  startIcon={"₹"}
                  type={"number"}
                  label={"One time Revenue"}
                  placeholder={"₹ 50,000"}
                  value={updateLead?.paySlab?.onetimeRevenue}
                  onChange={(e) =>
                    setUpdateLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        onetimeRevenue: e.target.value,
                      },
                    }))
                  }
                  backgroundColor={"#F6F6F6"}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={1.8} alignItems="center">
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Input
                  showAsterisk={true}
                  type={
                    updateLead?.paySlab?.mfamtType === "Flat amount"
                      ? "decimal"
                      : "number"
                  }
                  label={"Monthly fee"}
                  placeholder={"Enter Amount"}
                  value={updateLead?.paySlab?.monthlyFee}
                  maxLength={
                    updateLead?.paySlab?.mfamtType === "Flat amount" ? "" : 5
                  }
                  onChange={(e) =>
                    setUpdateLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        monthlyFee: e.target.value,
                      },
                    }))
                  }
                  endIcon={<img src={feeIcon} alt="fee icon" />}
                />
              </Grid>
              <Grid marginTop={1.5} item xs={6} sm={3} md={2} lg={1.5} xl={1}>
                <RadioButton
                  label={"Flat amount"}
                  value={"Flat amount"}
                  onChange={handleMonthlyFeeRadioButtonChange}
                  checked={updateLead?.paySlab?.mfamtType === "Flat amount"}
                />
              </Grid>
              <Grid marginTop={1.5} item xs={6} sm={3} md={2} lg={1.5} xl={1}>
                <RadioButton
                  label="Percentage"
                  value={"Percentage"}
                  onChange={handleMonthlyFeeRadioButtonChange}
                  checked={updateLead?.paySlab?.mfamtType === "Percentage"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={5}
                lg={6}
                xl={7}
                display={"flex"}
                justifyContent={"end"}
              >
                <Input
                  disabled={true}
                  startIcon={"₹"}
                  type={"number"}
                  label={"Monthly Revenue"}
                  placeholder={"₹ 30,000"}
                  value={updateLead?.paySlab?.calAmt}
                  onChange={(e) =>
                    setUpdateLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        calAmt: e.target.value,
                      },
                    }))
                  }
                  backgroundColor={"#F6F6F6"}
                />
              </Grid>
            </Grid>
          </Box>

          {/**Payment Terms */}
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: 500,
              color: " #505259",
            }}
          >
            {"Payment Terms"}
          </Typography>
          <Grid container marginBottom={1} spacing={1.8}>
            <Grid item xs={6} sm={3} md={1.8} lg={1.4} xl={1.4}>
              <RadioButton
                label={"Collect & Pay"}
                value={"Collect & Pay"}
                onChange={handleCollectAndPayChange}
                checked={updateLead?.paySlab?.paymentTerms === "Collect & Pay"}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={1.8} lg={1.4} xl={1.4}>
              <RadioButton
                label="Pay & Collect"
                value={"Pay & Collect"}
                onChange={handleCollectAndPayChange}
                checked={updateLead?.paySlab?.paymentTerms === "Pay & Collect"}
              />
            </Grid>
          </Grid>
          {updateLead?.paySlab?.paymentTerms === "Pay & Collect" && (
            <Grid container spacing={1.8}>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Input
                  type={"decimal"}
                  label={"Credit Period"}
                  placeholder={"Enter No Of Days"}
                  value={updateLead?.paySlab?.creditPeriod}
                  onChange={(e) =>
                    setUpdateLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        creditPeriod: e.target.value,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Input
                  type={"decimal"}
                  label={"Credit Limit"}
                  placeholder={"₹ Enter amount"}
                  value={updateLead?.paySlab?.creditLimit}
                  onChange={(e) =>
                    setUpdateLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        creditLimit: e.target.value,
                      },
                    }))
                  }
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Input
                disabled={true}
                startIcon={"₹"}
                type={"number"}
                label={"Salary Revenue"}
                placeholder={"₹ 8,00,000"}
                value={updateLead?.paySlab?.salRevenue}
                onChange={(e) =>
                  setUpdateLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      salRevenue: e.target.value,
                    },
                  }))
                }
                backgroundColor={"#F6F6F6"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Input
                disabled={true}
                startIcon={"₹"}
                type={"number"}
                label={"Payroll Revenue"}
                placeholder={"₹ 8,00,000"}
                value={updateLead?.paySlab?.payrollRevenue}
                onChange={(e) =>
                  setUpdateLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      payrollRevenue: e.target.value,
                    },
                  }))
                }
                backgroundColor={"#F6F6F6"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Input
                disabled={true}
                startIcon={"₹"}
                type={"number"}
                label={"Top Line Revenue"}
                placeholder={"₹ 8,00,000"}
                value={updateLead?.paySlab?.toplineRevenue}
                onChange={(e) =>
                  setUpdateLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      toplineRevenue: e.target.value,
                    },
                  }))
                }
                backgroundColor={"#F6F6F6"}
              />
            </Grid>
          </Grid>
          {/**Notes */}
          <Box>
            <SubHeading marginTop={1.5} text={"Notes"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: 500,
              color: " #505259",
            }}
          >
            {"Notes"}
          </Typography>

          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                multiline
                disabled={status === "active"}
                rows={4}
                variant="outlined"
                value={updateLead?.paySlab?.notes}
                onChange={(e) =>
                  setUpdateLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      notes: e.target.value,
                    },
                  }))
                }
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SubHeading text={" Scheduled Follow-ups"} marginTop={2} />
            <HorizontalLine marginBottom={2} />
          </Grid>

          <Grid item xs={12}>
            <Box>
              {followUpData && followUpData.length > 0 ? (
                <Suspense fallback={<Loader />}>
                  <FollowupCards
                    onUpdate={() => setRefresh((prev) => prev + 1)}
                    data={followUpData}
                  />
                </Suspense>
              ) : (
                <NoDataFound text="No followUps Found" />
              )}
            </Box>
          </Grid>
          <Box sx={{ my: 2 }}>
            <Button
              text={"New Follow-ups"}
              startIcon={<img src={Add} alt={"Add icon"} loading="lazy" />}
              color={"#0e3973"}
              borderColor={"#0e3973"}
              variant={"outlined"}
              onClick={() => setIsModalVisible(true)}
            />
          </Box>
          <Suspense fallback={<Loader />}>
            <AddFollowupModal
              onUpdate={() => setRefresh((prev) => prev + 1)}
              show={isModalVisible}
              userIds={leadData}
              leads={leadId}
              onClose={() => setIsModalVisible(false)}
            />
          </Suspense>
          <Grid
            sx={{ display: "flex", justifyContent: "end", gap: 2, mt: 2 }}
            container
          >
            <Button
              px={14}
              text={"Cancel"}
              color={"#E7554A"}
              borderColor={"#F9D5D2"}
              variant={"outlined"}
              onClick={() => {
                navigate(-1);
              }}
            />
            <Button
              px={13.6}
              text={"Update"}
              color={"#ffffff"}
              borderColor={"#3771C3)"}
              background={"var(--gradient1)"}
              variant={"outlined"}
              onClick={
                status === "active"
                  ? handleUpdateLeadSubmit
                  : handleUpdateSaveDraft
              }
            />
          </Grid>
        </Box>
      )}
    </Card>
  );
};

export default EditLeadInfo;
