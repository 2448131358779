import React, { useEffect, useState } from "react";
import { Box, IconButton, Grid } from "@mui/material";
import TimeModal from "../TimeModal/TimeModal.jsx";
import { useDispatch, useSelector } from "react-redux";
import { axiosPrivateCall } from "../../utilis/Rauth/Portauth.js";
import {
  setDashData,
  setDashDataError,
} from "../../redux/Slice/dashboardData.js";
import {
  setFromMonth,
  setFromYear,
  setToMonth,
  setToYear,
} from "../../redux/Slice/timeSlice.js";
import TypographyComponent from "../../commonComponents/Typography/index.jsx";
import CalendarMonthIcon from "../../assets/svg/calendarIcon.svg";

const DatePicker = ({ onDateChange }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const dateValues = useSelector((state) => state.date);
  const employee = useSelector((state) => state.verification?.user?.name);
  const dispatch = useDispatch();
  const { from, to } = dateValues;
  const { month: fromMonth, year: fromYear } = from;
  const { month: toMonth, year: toYear } = to;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const userId = useSelector((state) => state.verification?.user?.id);

  useEffect(() => {
    dashboardSubmit({ fromMonth, fromYear, toMonth, toYear });
  }, []);

  const dashboardSubmit = async (dates) => {
    const { fromMonth, fromYear, toMonth, toYear } = dates;
    try {
      const response = await axiosPrivateCall.get(
        `/dashBoard/getDashBoard/${userId}?fromMonth=${
          fromMonth + 1
        }&fromYear=${fromYear}&toMonth=${toMonth + 1}&toYear=${toYear}`
      );
      dispatch(setDashData(response.data.data));
      onDateChange(response.data.data.prospectVsClient);
    } catch (err) {
      dispatch(setDashDataError(err));
    }
  };

  const handleApplyModel = (selectedDates) => {
    dispatch(setFromMonth(selectedDates.fromMonth));
    dispatch(setFromYear(selectedDates.fromYear));
    dispatch(setToMonth(selectedDates.toMonth));
    dispatch(setToYear(selectedDates.toYear));
    dashboardSubmit(selectedDates);
    setModalOpen(false);
  };

  return (
    <>
      <Box>
        <TypographyComponent
          fontSize={"20px"}
          fontWeight={550}
          color={"#000000"}
          variant="body2"
          gutterBottom
        >
          {`Hi, ${employee ? employee : "guest"}`}
        </TypographyComponent>
        <Grid
          onClick={handleOpenModal}
          sx={{
            backgroundColor: "#2F5A91",
            borderRadius: 1,
            color: "white",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "space-around",
            padding: 1,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <TypographyComponent
              fontSize={"11.5px"}
              fontWeight={300}
              color={"#ffffff"}
              variant="caption"
            >
              From
            </TypographyComponent>
            <TypographyComponent
              fontSize={"13.5px"}
              fontWeight={500}
              color={"#ffffff"}
              variant="body2"
            >
              {`${months[fromMonth]} ${fromYear}`}
            </TypographyComponent>
          </Box>
          <IconButton>
            <img src={CalendarMonthIcon} alt={"calendar icon"} />
          </IconButton>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <TypographyComponent
              fontSize={"11.5px"}
              fontWeight={300}
              color={"#ffffff"}
              variant="caption"
            >
              To
            </TypographyComponent>
            <TypographyComponent
              fontSize={"13.5px"}
              fontWeight={500}
              color={"#ffffff"}
              variant="body2"
            >
              {`${months[toMonth]} ${toYear}`}
            </TypographyComponent>
          </Box>
        </Grid>
      </Box>
      <TimeModal
        open={modalOpen}
        handleClose={handleCloseModal}
        apply={handleApplyModel}
      />
    </>
  );
};

export default DatePicker;
