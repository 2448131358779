import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Card, Divider, Grid } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  COMPLETED,
  VIRTUAL,
  RESCHEDULED,
  CANCELLED,
} from "../../CommonMethods";
import GoBack from "../../components/GoBackButton";
import NoDataFound from "../../components/NoDataFound";
import { getLogActivity } from "../../redux/Slice/userStatus";
import { convertDateTimeIst } from "../../utilis/TimeZone";
import timeIcon from "../../assets/svg/timeLight.svg";
import { convertTimeIst } from "../../utilis/Date";
import styled from "@mui/system/styled";
import Loader from "../../components/Loader";
import locationIcon from "../../assets/svg/locationFilled.svg";
import { useDynamicHeight } from "../../hooks/useDynamicHeight";
import CustomTypography from "../../commonComponents/Typography";
import axios from "axios";

const LogActivity = () => {
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState();
  const dispatch = useDispatch();
  const logActivity =
    useSelector((state) => state?.userStatusData?.logActivity) || [];
  const token = useSelector((state) => state.verification?.token?.token);
  const leadId = useSelector((state) => state?.userStatusData?.leadId);
  const { createdDate, companyName, leadData } = useSelector(
    (state) => state?.userStatusData
  );

  const coords = leadData?.coords;

  useEffect(() => {
    if (coords) {
      const { lat, long } = coords;
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${process.env.REACT_APP_LOCATION_API_KEY}`;

      axios
        .get(geocodeUrl)
        .then((response) => {
          if (response.data.results.length > 0) {
            setLocation(response.data.results[0].formatted_address);
          } else {
            console.error("No address found for these coordinates.");
          }
        })
        .catch((error) => {
          console.error("Error fetching address:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("No coordinates provided.");
      setLoading(false);
    }
  }, [coords, dispatch]);

  useEffect(() => {
    dispatch(getLogActivity(leadId, token)).finally(() => setLoading(false));
  }, [dispatch, token, leadId]);

  const Item = styled("div")(({ theme }) => ({
    border: "1.4px solid #bdbdbd",
    padding: theme.spacing(1),
    borderRadius: "8px",
    textAlign: "center",
    lineHeight: 3,
  }));

  const groupByMonthYear = (data) => {
    return data.reduce((acc, item) => {
      const monthYear = format(new Date(item.updatedAt), "MMM yyyy");
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(item);
      return acc;
    }, {});
  };

  const groupedLogActivity = useMemo(
    () => groupByMonthYear(logActivity),
    [logActivity]
  );

  const parseData = (oldData, newData) => {
    const changes = [];
    oldData.forEach((item, index) => {
      if (item && newData[index]) {
        const oldItem = item.split(": ");
        const newItem = newData[index].split(": ");
        if (oldItem[1] !== newItem[1]) {
          changes.push({
            key: oldItem[0],
            from: oldItem[1],
            to: newItem[1],
          });
        }
      }
    });
    return changes;
  };

  function getColorCode(value) {
    switch (value.toLowerCase()) {
      case "client":
      case "clients":
        return "#2A80FF";
      case "hot":
        return "#E7554A";
      case "agreement":
        return "#52A13D";
      case "warm":
        return "#E3B53E";
      case "cold":
        return "#1d4a86";
      default:
        return null;
    }
  }

  function convertToUppercase(value) {
    const uppercaseValues = [
      "client",
      "clients",
      "hot",
      "agreement",
      "warm",
      "cold",
    ];
    return uppercaseValues.includes(value.toLowerCase())
      ? value.toUpperCase()
      : value;
  }

  const renderChanges = (changes, type) =>
    changes.map((change, index) => {
      const fromColor = getColorCode(change.from);
      const toColor = getColorCode(change.to);
      const isTimeChange =
        change.key === "Start Time" || change.key === "End Time";
      const fromValue = isTimeChange
        ? convertDateTimeIst(change.from)
        : change.key === "Virtual Meet"
        ? change.from === "true"
          ? "Virtual"
          : "In-person"
        : convertToUppercase(change.from);

      const toValue = isTimeChange
        ? convertDateTimeIst(change.to)
        : change.key === "Virtual Meet"
        ? change.to === "true"
          ? "Virtual"
          : "In-person"
        : convertToUppercase(change.to);

      const message = change.from
        ? `${change.key} changed from `
        : `${change.key} updated to `;

      return (
        <p key={index} style={{ textAlign: "left", paddingLeft: 6 }}>
          {message}
          {change.from && (
            <>
              <span style={{ fontWeight: 600, color: fromColor }}>
                {fromValue}
              </span>{" "}
              to{" "}
            </>
          )}
          <span style={{ fontWeight: 600, color: toColor }}>{toValue}</span>
        </p>
      );
    });

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd MMM, yyyy");
  };

  const screenSize = useDynamicHeight();
  const height = screenSize.dynamicHeight * 0.736;

  return (
    <Card
      className={"scrollbar-hidden "}
      sx={{
        backgroundColor: "#ffffff",
        overflow: "auto",
        height: height,
      }}
    >
      {loading ? (
        <Loader height={"73.2vh"} />
      ) : (
        <>
          <Box sx={{ display: "flex" }}>
            <GoBack heading={`${companyName} Log Activity`} />
            {logActivity && logActivity.length > 0 && (
              <Box sx={{ flex: "1", textAlign: "end", mr: 6.8, mt: 1.5 }}>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    lineHeight: "18.21px",
                    color: "var(--secondary)",
                    marginRight: "20px",
                  }}
                >
                  Lead Addition Date
                </span>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "18.21px",
                    color: "var(--secondary)",
                    marginRight: "3%",
                  }}
                >
                  {formatDate(createdDate)}
                </span>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    lineHeight: "18.21px",
                    color: "var(--secondary)",
                    marginRight: "20px",
                  }}
                >
                  Last Updated on
                </span>
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "18.21px",
                    color: "var(--secondary)",
                  }}
                >
                  {formatDate(logActivity[0].updatedAt)}
                </span>
              </Box>
            )}
          </Box>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {logActivity && logActivity.length > 0 ? (
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {Object.keys(groupedLogActivity).map((monthYear, index) => (
                    <div key={index}>
                      {index > 0 && (
                        <Divider
                          sx={{
                            my: 2,
                            borderBottomWidth: 1.5,
                            borderBottomStyle: "solid",
                          }}
                        />
                      )}
                      <TimelineItem sx={{ ml: 6 }}>
                        <TimelineSeparator sx={{ mt: 5.5, mb: 5 }}>
                          <TimelineDot
                            sx={{
                              backgroundColor: "#597ba7",
                              position: "absolute",
                              top: 18,
                              left: -4,
                            }}
                          />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Grid sx={{ ml: 4.5 }} item xs={12}>
                            <TimelineContent
                              sx={{
                                color: "#3771C3",
                                fontSize: 16,
                                fontWeight: 600,
                                position: "absolute",
                                left: -45,
                                top: -14,
                              }}
                            >
                              {monthYear}
                            </TimelineContent>
                            {groupedLogActivity[monthYear].map((item) => {
                              const { id, oldData, newData, type, updatedAt } =
                                item;
                              const changes =
                                oldData && newData
                                  ? parseData(oldData, newData)
                                  : [];

                              return (
                                <Box key={id} sx={{ m: 2.5 }}>
                                  <Grid container columns={12}>
                                    <Grid item xs={12}>
                                      <Item>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: 16,
                                            fontWeight: 600,
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "start",
                                            }}
                                          >
                                            {type === "FollowUp Cancelled" ? (
                                              <Box sx={{ px: 1 }}>
                                                Meeting Cancelled
                                              </Box>
                                            ) : (
                                              <Box sx={{ px: 1 }}>
                                                {type} {/**changed */}
                                              </Box>
                                            )}
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {type === "FollowUp" &&
                                              changes.some(
                                                (change) =>
                                                  change.key !==
                                                    "Virtual Meet" ||
                                                  (change.key ===
                                                    "Virtual Meet" &&
                                                    !(
                                                      change.from === "true" &&
                                                      change.to === "false"
                                                    ) &&
                                                    !(
                                                      change.from === "false" &&
                                                      change.to === "true"
                                                    ))
                                              ) && (
                                                <RESCHEDULED
                                                  key={id}
                                                  type="button"
                                                  size="small"
                                                  variant="outlined"
                                                >
                                                  Rescheduled
                                                </RESCHEDULED>
                                              )}
                                            {type === "FollowUp Cancelled" && (
                                              <Box>
                                                <CANCELLED
                                                  key={id}
                                                  type="button"
                                                  size="small"
                                                  variant="outlined"
                                                >
                                                  Cancelled
                                                </CANCELLED>
                                              </Box>
                                            )}
                                            {!(
                                              type === "FollowUp" &&
                                              changes.some(
                                                (change) =>
                                                  change.key !==
                                                    "Virtual Meet" ||
                                                  (change.key ===
                                                    "Virtual Meet" &&
                                                    !(
                                                      change.from === "true" &&
                                                      change.to === "false"
                                                    ) &&
                                                    !(
                                                      change.from === "false" &&
                                                      change.to === "true"
                                                    ))
                                              )
                                            ) &&
                                              changes.map((change, index) => {
                                                if (
                                                  type === "FollowUp" &&
                                                  change.key === "Virtual Meet"
                                                ) {
                                                  if (
                                                    change.from === "true" &&
                                                    change.to === "false"
                                                  ) {
                                                    return (
                                                      <COMPLETED
                                                        key={index}
                                                        type="button"
                                                        size="small"
                                                        variant="outlined"
                                                      >
                                                        Client Location
                                                      </COMPLETED>
                                                    );
                                                  } else if (
                                                    change.from === "false" &&
                                                    change.to === "true"
                                                  ) {
                                                    return (
                                                      <VIRTUAL
                                                        key={index}
                                                        type="button"
                                                        size="small"
                                                        variant="outlined"
                                                      >
                                                        Virtual
                                                      </VIRTUAL>
                                                    );
                                                  }
                                                }
                                                return null;
                                              })}
                                          </Box>
                                        </Box>
                                        <Box
                                          sx={{
                                            px: 0.5,
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            fontWeight: "400",
                                            fontSize: 12,
                                            color: "var(--secondary)",
                                          }}
                                        >
                                          <img
                                            src={locationIcon}
                                            alt="Time icon"
                                          />
                                          {location}
                                          <span>{"-"}</span>
                                          <CustomTypography
                                            fontWeight={700}
                                            fontSize={12}
                                          >
                                            {convertTimeIst(updatedAt)}
                                          </CustomTypography>
                                        </Box>
                                        {type === "FollowUp Cancelled" ? (
                                          <p
                                            style={{
                                              textAlign: "left",
                                              fontSize: 16,
                                              marginLeft: "0.8%",
                                            }}
                                          >
                                            The Scheduled Meeting has been
                                            cancelled
                                          </p>
                                        ) : (
                                          renderChanges(changes, type)
                                        )}
                                      </Item>
                                    </Grid>
                                  </Grid>
                                </Box>
                              );
                            })}
                          </Grid>
                          <TimelineSeparator
                            sx={{ position: "absolute", left: -5, bottom: 14 }}
                          >
                            <TimelineDot sx={{ backgroundColor: "#597ba7" }} />
                          </TimelineSeparator>
                        </TimelineContent>
                      </TimelineItem>
                    </div>
                  ))}
                </Timeline>
              ) : (
                <NoDataFound height={"62.8vh"} />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  );
};

export default LogActivity;
