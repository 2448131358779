import React, { useState } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Layoutpage/routes";
import noWifi from "./assets/png/wifi.png";
import { Box } from "@mui/material";
import NetworkStatus from "./components/NetworkStatus"

function App() {
  const [isOffline, setIsOffline] = useState(false);

  return (
    <div>
      <NetworkStatus setIsOffline={setIsOffline} />
      {isOffline ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#f8d7da",
            color: "#721c24",
            height: "100vh",
          }}
        >
          <img width={100} src={noWifi} alt={"noWifi icon"} loading={"lazy"} />
          Please check your network connection.
        </Box>
      ) : (
        <RouterProvider router={router} />
      )}
    </div>
  );
}

export default App;
