import { createBrowserRouter } from "react-router-dom";
import LayoutPage from "../Layoutpage/Layoutpage";
import Login from "../pages/Loginpage/Login";
import LeadManagement from "../pages/LeadManagement";
import Dashboard from "../pages/Dashboardpage/Dashboard";
import LeadInfo from "../pages/LeadInfo/leadInfo";
import FollowupHistory from "../pages/FollowupHistory/FollowupHistory";
import LogActivity from "../pages/LogActivity";
import ProtectedRoute from "../utilis/Rauth/Protect";
import AddLead from "../pages/AddLead";
import ErrorPage from "../components/ErrorPage";
import NonAuthRoute from "../utilis/Rauth/NonAuthRoute";
import EditLeadInfo from "../pages/EditLeadInfo";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <NonAuthRoute element={<Login />} />,
  },
  {
    path: "/home",
    element: <ProtectedRoute element={<LayoutPage />} />,
    children: [
      {
        path: "leadManagement",
        element: <ProtectedRoute element={<LeadManagement />} />,
      },
      {
        path: "dashboard",
        element: <ProtectedRoute element={<Dashboard />} />,
      },
      {
        path: "leadInfo",
        element: <ProtectedRoute element={<LeadInfo />} />,
      },
      {
        path: "followUpHistory",
        element: <ProtectedRoute element={<FollowupHistory />} />,
      },
      {
        path: "logActivity",
        element: <ProtectedRoute element={<LogActivity />} />,
      },
      {
        path: "addLead",
        element: <ProtectedRoute element={<AddLead />} />,
      },
      {
        path: "editLeadInfo",
        element: <ProtectedRoute element={<EditLeadInfo />} />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);
