import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  lazy,
  Suspense,
} from "react";
import logAct from "../../assets/svg/logAct.svg";
import add from "../../assets/svg/add.svg";
import historyIcon from "../../assets/svg/historyIcon.svg";
import { Box, Card, Grid, TextField } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GoBack from "../../components/GoBackButton";
import {
  roundValue,
  COLD_BUTTON,
  HOT_BUTTON,
  WARM_BUTTON,
  CLIENTS_BUTTON,
  AGREEMENT_BUTTON,
  filteredName,
} from "../../CommonMethods";
import { formatSignUpMonth } from "../../utilis/Date/index.jsx";
import Loader from "../../components/Loader";
import NoDataFound from "../../components/NoDataFound/index.jsx";
import { getFollowUpLead, getLeadData } from "../../redux/Slice/userStatus.js";
import TypographyComponent from "../../commonComponents/Typography/index.jsx";
import SubHeading from "../../commonComponents/SubHeading/index.jsx";
import HorizontalLine from "../../commonComponents/HorizontalLine/index.jsx";
import Button from "../../commonComponents/Button/index.jsx";
import { useDynamicHeight } from "../../hooks/useDynamicHeight/index.jsx";
import EditIcon from "../../assets/svg/editBtn.svg";
import TruncatedText from "../../commonComponents/TruncatedText/index.jsx";

const FollowupCards = lazy(() =>
  import("../../components/FollowupCards/FollowupCards")
);
const AddFollowupModal = lazy(() =>
  import("../../components/AddFollowUpModal")
);

const LeadInfo = () => {
  const [show, setShow] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const { leadId, followUpData, leadData, status } = useSelector(
    (state) => state.userStatusData
  );
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const handleHistoryClick = () => {
    navigate("/home/followUpHistory");
  };

  const handleLogActivityClick = () => {
    navigate("/home/logActivity");
  };

  const handleEditClick = () => {
    navigate(`/home/editLeadInfo`);
  };

  const getIndividualLead = useCallback(
    async (id) => {
      try {
        setLoading(true);
        await dispatch(getFollowUpLead(id)); // Await to ensure API call completes before proceeding
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );

  const getLeads = useCallback(async () => {
    try {
      await dispatch(getLeadData(leadId)); // Await to ensure API call completes
      if (leadData?.id) {
        getIndividualLead(leadId);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  }, [dispatch, leadId, leadData?.id, getIndividualLead]);

  useEffect(() => {
    getLeads();
  }, [leadId, getLeads]);

  useEffect(() => {
    if (refresh > 0 && leadData?.id) {
      getIndividualLead(leadId);
    }
  }, [refresh, leadId, leadData?.id, getIndividualLead]);

  const getButton = (type) => {
    switch (type) {
      case "Warm":
        return (
          <WARM_BUTTON type="button" size="small" variant="outlined">
            Warm
          </WARM_BUTTON>
        );
      case "Hot":
        return (
          <HOT_BUTTON type="button" size="small" variant="outlined">
            Hot
          </HOT_BUTTON>
        );
      case "Cold":
        return (
          <COLD_BUTTON type="button" size="small" variant="outlined">
            Cold
          </COLD_BUTTON>
        );
      case "Client":
        return (
          <CLIENTS_BUTTON type="button" size="small" variant="outlined">
            Clients
          </CLIENTS_BUTTON>
        );
      case "Clients":
        return (
          <CLIENTS_BUTTON type="button" size="small" variant="outlined">
            Clients
          </CLIENTS_BUTTON>
        );
      case "Agreement":
        return (
          <AGREEMENT_BUTTON type="button" size="small" variant="outlined">
            Agreement
          </AGREEMENT_BUTTON>
        );
      default:
        return null;
    }
  };

  const memoizedLeadsData = useMemo(() => leadData, [leadData]);

  const screenSize = useDynamicHeight();
  const height = screenSize.dynamicHeight * 0.736;

  return (
    <Card
      className={"scrollbar-hidden "}
      sx={{
        backgroundColor: "#ffffff",
        height: height,
        overflow: "auto",
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={9}>
          <GoBack heading={memoizedLeadsData?.companyName} />
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginRight: 2,
              }}
              onClick={handleEditClick}
            >
              <img src={EditIcon} alt="edit-icon" loading="lazy" />
              <span
                className={"custom-underline"}
                style={{ marginLeft: "4px", color: "#3771C3" }}
              >
                Edit
              </span>
            </Box>

            {status === "active" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginRight: 2,
                }}
                onClick={handleLogActivityClick}
              >
                <img src={logAct} alt="log-activity-icon" loading="lazy" />
                <span
                  className={"custom-underline"}
                  style={{ marginLeft: "4px", color: "#3771C3" }}
                >
                  Log Activity
                </span>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      {loading ? (
        <Loader height="52vh" />
      ) : (
        <Box sx={{ px: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <SubHeading text={"Lead Information"} />
                <HorizontalLine marginBottom={1} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                  <TypographyComponent
                    fontWeight={"500"}
                    fontSize={15}
                    color={"var(--secondary)"}
                  >
                    Company Name
                  </TypographyComponent>
                </Grid>
                <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                  <TypographyComponent
                    fontWeight={"400"}
                    fontSize={15}
                    color={"var(--light-gray)"}
                  >
                    <TruncatedText
                      text={memoizedLeadsData?.companyName || "-"}
                      maxLength={25}
                    />

                    {/* {memoizedLeadsData?.companyName
                      ? filteredName(memoizedLeadsData?.companyName, 25)
                      : "-"} */}
                  </TypographyComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                  <TypographyComponent
                    fontWeight={"500"}
                    fontSize={15}
                    color={"var(--secondary)"}
                  >
                    Industry Type
                  </TypographyComponent>
                </Grid>
                <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                  <TypographyComponent
                    fontWeight={"400"}
                    fontSize={15}
                    color={"var(--light-gray)"}
                  >
                    {memoizedLeadsData?.industry
                      ? filteredName(memoizedLeadsData?.industry, 23)
                      : "-"}
                  </TypographyComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                  <TypographyComponent
                    fontWeight={"500"}
                    fontSize={15}
                    color={"var(--secondary)"}
                  >
                    Type
                  </TypographyComponent>
                </Grid>
                <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                  <TypographyComponent
                    fontWeight={"400"}
                    fontSize={15}
                    color={"var(--light-gray)"}
                  >
                    {memoizedLeadsData?.paySlab?.type
                      ? getButton(memoizedLeadsData?.paySlab?.type)
                      : "-"}
                  </TypographyComponent>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                  <TypographyComponent
                    fontWeight={"500"}
                    fontSize={15}
                    color={"var(--secondary)"}
                  >
                    State
                  </TypographyComponent>
                </Grid>
                <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                  <TypographyComponent
                    fontWeight={"400"}
                    fontSize={15}
                    color={"var(--light-gray)"}
                  >
                    {memoizedLeadsData?.state || "-"}
                  </TypographyComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                  <TypographyComponent
                    fontWeight={"500"}
                    fontSize={15}
                    color={"var(--secondary)"}
                  >
                    City
                  </TypographyComponent>
                </Grid>
                <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                  <TypographyComponent
                    fontWeight={"400"}
                    fontSize={15}
                    color={"var(--light-gray)"}
                  >
                    {memoizedLeadsData?.city || "-"}
                  </TypographyComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                  <TypographyComponent
                    fontWeight={"500"}
                    fontSize={15}
                    color={"var(--secondary)"}
                  >
                    Sign Up Month
                  </TypographyComponent>
                </Grid>
                <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                  <TypographyComponent
                    fontWeight={"400"}
                    fontSize={15}
                    color={"var(--light-gray)"}
                  >
                    {memoizedLeadsData?.paySlab?.signupMonth
                      ? formatSignUpMonth(
                          memoizedLeadsData?.paySlab?.signupMonth
                        )
                      : "-"}
                  </TypographyComponent>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                  <TypographyComponent
                    fontWeight={"500"}
                    fontSize={15}
                    color={"var(--secondary)"}
                  >
                    Average Salary
                  </TypographyComponent>
                </Grid>
                <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                  <TypographyComponent
                    fontWeight={"400"}
                    fontSize={15}
                    color={"var(--light-gray)"}
                  >
                    {memoizedLeadsData?.paySlab?.avgSalary
                      ? `Rs. ${roundValue(
                          memoizedLeadsData?.paySlab?.avgSalary || 0
                        )}`
                      : "-"}
                  </TypographyComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                  <TypographyComponent
                    fontWeight={"500"}
                    fontSize={15}
                    color={"var(--secondary)"}
                  >
                    Latitude
                  </TypographyComponent>
                </Grid>
                <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                  <TypographyComponent
                    fontWeight={"400"}
                    fontSize={15}
                    color={"var(--light-gray)"}
                  >
                    {memoizedLeadsData?.lat || "-"}
                  </TypographyComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container alignItems="center">
                <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                  <TypographyComponent
                    fontWeight={"500"}
                    fontSize={15}
                    color={"var(--secondary)"}
                  >
                    Longitude
                  </TypographyComponent>
                </Grid>
                <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                  <TypographyComponent
                    fontWeight={"400"}
                    fontSize={15}
                    color={"var(--light-gray)"}
                  >
                    {memoizedLeadsData?.lon || "-"}
                  </TypographyComponent>
                </Grid>
              </Grid>
            </Grid>

            {show && memoizedLeadsData?.contactPerson?.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Box>
                    <SubHeading text={"Contact Details"} />
                    <HorizontalLine />
                  </Box>
                </Grid>

                {memoizedLeadsData?.contactPerson?.map((item, index) => (
                  <Grid marginTop={1} mx={0} container spacing={2} key={index}>
                    <Grid item xs={12} sm={4}>
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                          <TypographyComponent
                            fontWeight={"500"}
                            fontSize={15}
                            color={"var(--secondary)"}
                          >
                            Name
                          </TypographyComponent>
                        </Grid>
                        <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                          <TypographyComponent
                            fontWeight={"400"}
                            fontSize={15}
                            color={"var(--light-gray)"}
                          >
                            {item?.name ? filteredName(item?.name, 20) : "-"}
                          </TypographyComponent>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                          <TypographyComponent
                            fontWeight={"500"}
                            fontSize={15}
                            color={"var(--secondary)"}
                          >
                            Contact
                          </TypographyComponent>
                        </Grid>
                        <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                          <TypographyComponent
                            fontWeight={"400"}
                            fontSize={15}
                            color={"var(--light-gray)"}
                          >
                            {item?.contact || "-"}
                          </TypographyComponent>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                          <TypographyComponent
                            fontWeight={"500"}
                            fontSize={15}
                            color={"var(--secondary)"}
                          >
                            Mail ID
                          </TypographyComponent>
                        </Grid>
                        <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                          <TypographyComponent
                            fontWeight={"400"}
                            fontSize={15}
                            color={"var(--light-gray)"}
                          >
                            <TruncatedText
                              text={item?.mailId || "-"}
                              maxLength={25}
                            />
                          </TypographyComponent>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container alignItems="center">
                        <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                          <TypographyComponent
                            fontWeight={"500"}
                            fontSize={15}
                            color={"var(--secondary)"}
                          >
                            Designation
                          </TypographyComponent>
                        </Grid>
                        <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                          <TypographyComponent
                            fontWeight={"400"}
                            fontSize={15}
                            color={"var(--light-gray)"}
                          >
                            {item?.designation
                              ? filteredName(item?.designation, 20)
                              : "-"}
                          </TypographyComponent>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}

            {show && memoizedLeadsData?.paySlab && (
              <>
                <Grid item xs={12}>
                  <Box>
                    <SubHeading text={"Existing & Planned Numbers"} />
                    <HorizontalLine marginBottom={1} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        Existing Count
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.existingNumber || "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        Sourcing Count
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.sourcing || "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        Transfer Count
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.transfer || "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        Total Count
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.totplannedndNum || "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <SubHeading text={"Sourcing Details"} />
                    <HorizontalLine marginBottom={1} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        One Time Fee
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.onetimeFee
                          ? `₹ ${roundValue(
                              memoizedLeadsData?.paySlab?.onetimeFee
                            )}`
                          : "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        Monthly Fee
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.monthlyFee
                          ? `₹ ${roundValue(
                              memoizedLeadsData?.paySlab?.monthlyFee
                            )}`
                          : "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        Payment Term
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.paymentTerms || "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        Credit Period
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.creditPeriod || "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        Credit Limit
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.creditLimit
                          ? `₹ ${roundValue(
                              memoizedLeadsData?.paySlab?.creditLimit
                            )}`
                          : "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        Salary Revenue
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.salRevenue
                          ? `₹ ${roundValue(
                              memoizedLeadsData?.paySlab?.salRevenue
                            )}`
                          : "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        Payroll Revenue
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.payrollRevenue
                          ? `₹ ${roundValue(
                              memoizedLeadsData?.paySlab?.payrollRevenue
                            )}`
                          : "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container alignItems="center">
                    <Grid item xs={4.5} sm={6} md={5} lg={5} xl={5}>
                      <TypographyComponent
                        fontWeight={"500"}
                        fontSize={15}
                        color={"var(--secondary)"}
                      >
                        TopLine Revenue
                      </TypographyComponent>
                    </Grid>
                    <Grid item xs={7.5} sm={6} md={7} lg={7} xl={7}>
                      <TypographyComponent
                        fontWeight={"400"}
                        fontSize={15}
                        color={"var(--light-gray)"}
                      >
                        {memoizedLeadsData?.paySlab?.toplineRevenue
                          ? `₹ ${roundValue(
                              memoizedLeadsData?.paySlab?.toplineRevenue
                            )}`
                          : "-"}
                      </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <SubHeading text={"Notes"} />
                    <HorizontalLine marginBottom={1} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    disabled
                    multiline
                    rows={4}
                    variant="outlined"
                    value={memoizedLeadsData?.paySlab?.notes}
                    fullWidth
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#e9e9e9",
                  cursor: "pointer",
                  borderRadius: "2px",
                  color: "#B9BABD",
                  marginBottom: "2vh",
                }}
              >
                <ExpandMoreOutlinedIcon
                  onClick={() => setShow(!show)}
                  style={{
                    transform: show ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <SubHeading text={" Scheduled Follow-ups"} />

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleHistoryClick}
                >
                  <img src={historyIcon} alt="history icon" loading="lazy" />
                  <span
                    className={"custom-underline"}
                    style={{ marginLeft: "4px", color: "#3771C3" }}
                  >
                    History
                  </span>
                </Box>
              </Box>
              <HorizontalLine marginBottom={1} />
            </Grid>

            <Grid item xs={12}>
              <Box>
                {followUpData && followUpData?.length > 0 ? (
                  <Suspense fallback={<Loader />}>
                    <FollowupCards
                      onUpdate={() => setRefresh((prev) => prev + 1)}
                      data={followUpData}
                    />
                  </Suspense>
                ) : (
                  <NoDataFound text="No followUps Found" />
                )}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ my: 2 }}>
            <Button
              text={"Add Follow-up"}
              startIcon={<img src={add} alt={"Add icon"} loading="lazy" />}
              color={"#0e3973"}
              borderColor={"#0e3973"}
              variant={"outlined"}
              onClick={() => setIsModalVisible(true)}
            />
          </Box>
        </Box>
      )}

      <Suspense fallback={<Loader />}>
        <AddFollowupModal
          onUpdate={() => setRefresh((prev) => prev + 1)}
          show={isModalVisible}
          userIds={leadData}
          leads={leadId}
          onClose={() => setIsModalVisible(false)}
          isCurrentLocation={""}
        />
      </Suspense>
    </Card>
  );
};

export default LeadInfo;
