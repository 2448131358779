import moment from "moment";

export function convertDateTime(inputDateTime) {
  if (!inputDateTime) {
    return "-";
  }
  return moment(inputDateTime).format("HH:mm A");
}

export function convertTime(inputDateTime) {
  if (!inputDateTime) {
    return "-";
  }
  return moment(inputDateTime).format("HH:mm");
}

export function formatTime(timeString) {
  if (!timeString) {
    return "-";
  }
  return moment(timeString, "HH:mm").format("hh:mm a");
}

export function formatMonthYear(dateString) {
  return moment(dateString).format("MMMM YYYY");
}

export const formatSignUpMonth = (response) => {
  // Parse the input string using moment.js
  const date = moment(response);

  // Format the date to display only month and year
  if (date.isValid()) {
    return date.format("MMMM YYYY");
  }

  // If parsing fails, return the response as is or handle accordingly
  return response;
};

export const timeDiffer = (endTime, startTime) => {
  const end = moment(endTime, "HH:mm");
  const start = moment(startTime, "HH:mm");
  return end.diff(start, "minutes");
};

export const convertToISO = (timeStr, dateStr) => {
  const [hours, minutes] = timeStr.split(":").map(Number);
  const [year, month, day] = dateStr.split("-").map(Number);
  const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));
  return date.toISOString();
};

export const convertToIST = (date) => {
  const utcDate = moment(date);
  const istDate = utcDate.add(5, "hours").add(30, "minutes").format("hh:mm A");
  return istDate;
};

export function formatDateMonthYear(dateString) {
  return moment(dateString).format("DD MMMM YYYY");
}

export const formatTimeLeft = (startTime, endTime) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const now = moment();

  let timeLeftString = "";

  if (now.isBefore(start)) {
    // Time is before the start time
    const timeLeftStart = moment.duration(start.diff(now));
    const daysStart = Math.floor(timeLeftStart.asDays());
    const hoursStart = Math.floor(timeLeftStart.asHours() % 24);
    const minutesStart = Math.floor(timeLeftStart.asMinutes() % 60);
    const secondsStart = Math.floor(timeLeftStart.asSeconds() % 60);

    if (daysStart > 0 && hoursStart === 0 && minutesStart === 0 && secondsStart === 0) {
      timeLeftString = `${daysStart} days left`;
    } else if (daysStart > 0) {
      timeLeftString = `${daysStart} days ${hoursStart} hrs left`;
    } else if (hoursStart > 0) {
      timeLeftString = `${hoursStart} hrs left`;
    } else if (minutesStart > 0) {
      timeLeftString = `${minutesStart} mins left`;
    } else if (secondsStart > 0) {
      timeLeftString = `${secondsStart} secs left`;
    }
  } else if (now.isBetween(start, end, null, '[)')) {
    // Time is between start and end time
    timeLeftString = "Ongoing";
  } else if (now.isSameOrAfter(end)) {
    // Time is after the end time
    timeLeftString = "Ended";
  }

  return timeLeftString;
};





export function convertTimeIst(inputDateTime) {
  if (!inputDateTime) {
    return "-";
  }
  return moment.utc(inputDateTime).utcOffset("+05:30").format("hh:mm A");
}

export const UTCFormat = (date) => {
  //const parsedDate = moment(date, 'ddd MMM DD YYYY HH:mm:ss ZZ');
  const parsedDate = moment.utc(date, moment.ISO_8601);

  // Format the parsed date into "YYYY-MM-DDTHH:mm:ss.SSSZ"
  return parsedDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
};
