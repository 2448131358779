import { InputAdornment, TextField, Box } from "@mui/material";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";

export const TOGGLE_BUTTON = [
  { value: "active", label: "Active" },
  { value: "draft", label: "Draft" },
];

// Custom styled components
export const SEARCH_TEXT_FIELD = styled(TextField)({
  width: "220px",
  padding: "4px 8px 4px 4px",
  borderRadius: "24px",
  background: "var(--gradient2)",
  height: 22,
  display: "flex",
  alignItems: "center",
  "& .MuiOutlinedInput-root": {
    display: "flex", // added
    alignItems: "center", // added
    height: "100%", // ensures full height of the parent
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "14px",
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.02em",
    marginLeft: 4,
    color: "#44464C",
  },
});

export const SORT_AND_FILTER_BUTTON = styled(Button)({
  width: "200px",
  padding: "4px 8px 4px 4px",
  borderRadius: "24px",
  background: "var(--gradient2)",
  height: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  textTransform: "none", // Prevents automatic uppercasing of button text
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "16px",
  letterSpacing: "0.02em",
  "&:hover": {
    background: "var(--gradient2)",
  },
});

export const ICON_WRAPPER_SEARCH = styled(InputAdornment)({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
});

export const FILTER_TEXT_FIELD = styled(TextField)({
  width: "100%",
  padding: "4px 0px 4px 0px",
  border: "1px solid #D1D1D1",
  borderRadius: "4px",
  background: "#FFFFFF",
  height: 22,
  display: "flex",
  alignItems: "center",
  "& .MuiOutlinedInput-root": {
    display: "flex",
    alignItems: "center",
    height: "100%",
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "14px",
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.02em",
    marginLeft: 4,
    color: "#44464C",
  },
});

export const WARM_BUTTON = styled(Button)(() => ({
  color: "#E3B53E",
  backgroundColor: "rgba(227, 181, 62, 0.1)",
  borderRadius: "8px",
  border: "1px solid #E3B53E",
  fontSize: 12,
  minWidth: "15px",
  padding: " 3px 11px 3px 11px",
  textTransform: "none",
  "&:hover": {
    color: "#E3B53E",
    backgroundColor: "rgba(227, 181, 62, 0.1)",
    border: "1px solid #E3B53E",
  },
}));

export const HOT_BUTTON = styled(Button)(() => ({
  color: "#E12B1D",
  backgroundColor: "rgba(225, 43, 29, 0.1)",
  borderRadius: "8px",
  border: "1px solid #E12B1D",
  fontSize: 12,
  minWidth: "15px",
  padding: " 3px 11px 3px 11px",
  textTransform: "none",
  "&:hover": {
    color: "#E12B1D",
    backgroundColor: "rgba(225, 43, 29, 0.1)",
    border: "1px solid #E12B1D",
  },
}));

export const COLD_BUTTON = styled(Button)(() => ({
  color: "#17437f",
  borderRadius: "8px",
  border: "1px solid #17437f",
  fontSize: 12,
  minWidth: "15px",
  padding: " 3px 11px 3px 11px",
  textTransform: "none",
  "&:hover": {
    color: "#17437f",
    border: "1px solid #17437f",
  },
}));

export const AGREEMENT_BUTTON = styled(Button)(() => ({
  color: "#3A732B",
  backgroundColor: "#F1FBF0",
  borderRadius: "8px",
  padding: "4px, 12px, 4px, 12px",
  border: "1px solid #6BCB52",
  fontSize: 12,
  minWidth: "15px",
  padding: " 3px 11px 3px 11px",
  textTransform: "none",
  "&:hover": {
    color: "#3A732B",
    backgroundColor: "#F1FBF0",
    border: "1px solid #6BCB52",
  },
}));

export const CLIENTS_BUTTON = styled(Button)(() => ({
  color: "#026AFC",
  backgroundColor: "#EEF5FC",
  borderRadius: "8px",
  border: "1px solid #026AFC",
  fontSize: 12,
  minWidth: "15px",
  padding: " 3px 11px 3px 11px",
  textTransform: "none",
  "&:hover": {
    color: "#026AFC",
    backgroundColor: "#EEF5FC",
    border: "1px solid #026AFC",
  },
}));

export const RESCHEDULED_BUTTON = styled(Button)(() => ({
  color: "#E3B53E",
  backgroundColor: "rgba(227, 181, 62, 0.1)",
  borderRadius: "8px",
  border: "1px solid #E3B53E",
  fontSize: 12,
  minWidth: "15px",
  padding: " 3px 11px 3px 11px",
  textTransform: "none",
  "&:hover": {
    color: "#E3B53E",
    backgroundColor: "rgba(227, 181, 62, 0.1)",
    border: "1px solid #E3B53E",
  },
}));

export const SCROLLABLE_BOX = styled(Box)({
  maxHeight: 230,
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#DBDBDB",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#DBDBDB",
  },
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
});

export const FILTERED_SCROLLABLE_BOX = styled(Box)({
  maxHeight: 266,
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#DBDBDB",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#DBDBDB",
  },
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
});

export const CapitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const RESCHEDULED = styled(Button)(() => ({
  color: "#DA7800",
  backgroundColor: "rgba(227, 181, 62, 0.1)",
  borderRadius: "8px",
  padding: "4px, 12px, 4px, 12px",
  gap: "10px",
  border: "1.4px solid #F28500",
  textTransform: "none",
  "&:hover": {
    color: "#DA7800",
    backgroundColor: "rgba(227, 181, 62, 0.1)",
    border: "1.4px solid #F28500",
  },
}));

export const CANCELLED = styled(Button)(() => ({
  color: "#E12B1D",
  backgroundColor: "rgba(225, 43, 29, 0.1)",
  borderRadius: "8px",
  padding: "4px, 12px, 4px, 12px",
  gap: "10px",
  border: "1.4px solid #E12B1D",
  textTransform: "none",
  "&:hover": {
    color: "#E12B1D",
    backgroundColor: "rgba(225, 43, 29, 0.1)",
    border: "1.4px solid #E12B1D",
  },
}));

export const COMPLETED = styled(Button)(() => ({
  color: "#07BE4A",
  backgroundColor: "#F1FBF0",
  borderRadius: "8px",
  padding: "4px, 12px, 4px, 12px",
  gap: "10px",
  border: "1.4px solid #6BCB52",
  textTransform: "none",
  "&:hover": {
    color: "#07BE4A",
    backgroundColor: "#F1FBF0",
    border: "1.4px solid #6BCB52",
  },
}));

export const VIRTUAL = styled(Button)(() => ({
  color: "#357AF6",
  backgroundColor: "#e6effe",
  borderRadius: "8px",
  padding: "4px, 12px, 4px, 12px",
  gap: "10px",
  border: "1.4px solid #357AF6",
  textTransform: "none",
  "&:hover": {
    color: "#357AF6",
    backgroundColor: "#e6effe",
    border: "1.4px solid #357AF6",
  },
}));
export const roundValue = (value) => {
  const roundedValue = Math.round(Number(value)).toString();

  // Function to insert commas as per the Indian Numbering System
  const formatWithCommas = (num) => {
    const [integerPart, decimalPart] = num.split(".");
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);

    if (otherDigits !== "") {
      lastThreeDigits = "," + lastThreeDigits;
    }

    const formattedOtherDigits = otherDigits.replace(
      /\B(?=(\d{2})+(?!\d))/g,
      ","
    );
    return (
      formattedOtherDigits +
      lastThreeDigits +
      (decimalPart ? "." + decimalPart : "")
    );
  };

  return formatWithCommas(roundedValue);
};

export const SAMPLE_DATA = [
  {
    CompanyName: "Company A",
    Industry: "Agriculture",
    State: "Tamil Nadu",
    City: "Chennai",
    Location: "Location A",
    Lat: 12.9716,
    Lon: 77.5946,
    Type: "Cold",
    SignupMonth: "January 2024",
    AvgSalary: 50000,
    ExistingNumber: 10,
    Sourcing: "10",
    Transfer: "2",
    TotplannedndNum: 12,
    OnetimeFee: 1000,
    OtfamtType: "Flat amount",
    OnetimeRevenue: 5000,
    MonthlyFee: 100,
    MfamtType: "Flat amount",
    CalAmt: 500,
    PaymentTerms: "Pay & Collect",
    CreditPeriod: 30,
    CreditLimit: 100000,
    SalRevenue: 10000,
    PayrollRevenue: 3000,
    ToplineRevenue: 15000,
    Notes:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    ContactName: "Akshay G",
    ContactNumber: 8754070533,
    mailId: "akshayg@gmail.com",
    designation: "React js",
    companySize: "150-200",
    status: "active",
  },
  {
    CompanyName: "Company B",
    Industry: "Chemicals",
    State: "Kerala",
    City: "Pathanamthitta",
    Location: "Location B",
    Lat: 12.9716,
    Lon: 77.5946,
    Type: "Warm",
    SignupMonth: "February 2025",
    AvgSalary: 50000,
    ExistingNumber: 10,
    Sourcing: "10",
    Transfer: "2",
    TotplannedndNum: 12,
    OnetimeFee: 1000,
    OtfamtType: "Flat amount",
    OnetimeRevenue: 5000,
    MonthlyFee: 100,
    MfamtType: "Flat amount",
    CalAmt: 500,
    PaymentTerms: "Pay & Collect",
    CreditPeriod: 30,
    CreditLimit: 100000,
    SalRevenue: 10000,
    PayrollRevenue: 3000,
    ToplineRevenue: 15000,
    Notes:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    ContactName: "Abcde",
    ContactNumber: 8754070533,
    mailId: "Abcde@gmail.com",
    designation: "React native",
    companySize: "150-200",
    status: "active",
  },
];

export const filteredName = (value, length) => {
  if (value?.length > length) {
    return value.substring(0, length).trim() + "...";
  } else {
    return value;
  }
};

// Function to insert commas as per the Indian Numbering System
export const formatWithCommas = (num) => {
  const [integerPart, decimalPart] = num.split(".");
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  const formattedOtherDigits = otherDigits.replace(
    /\B(?=(\d{2})+(?!\d))/g,
    ","
  );
  return (
    formattedOtherDigits +
    lastThreeDigits +
    (decimalPart ? "." + decimalPart : "")
  );
};

export const formatIndianNumber = (num) => {
  // Ensure num is a number and convert it to a float
  num = parseFloat(num);

  // Check if num is a valid number
  if (isNaN(num)) {
    return "Invalid number"; // Handle invalid input
  }

  // Convert the number to a string with exactly two decimal places
  let [integerPart, decimalPart] = num.toString().split(".");

  // Format the integer part according to the Indian numbering system
  let x = integerPart.toString();
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);

  if (otherNumbers !== "") {
    lastThree = "," + lastThree;
  }

  let formattedIntegerPart =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

  // Combine the formatted integer part with the decimal part if it exists
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart.padEnd(2, "0")}`
    : formattedIntegerPart;
};

export const months = [
  { label: "January", value: "january" },
  { label: "February", value: "february" },
  { label: "March", value: "march" },
  { label: "April", value: "april" },
  { label: "May", value: "may" },
  { label: "June", value: "june" },
  { label: "July", value: "july" },
  { label: "August", value: "august" },
  { label: "September", value: "september" },
  { label: "October", value: "october" },
  { label: "November", value: "november" },
  { label: "December", value: "december" },
];


