import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom"

const ProtectedRoute = ({element}) => {

    const token = useSelector((state)=>state.verification?.token)
    let location = useLocation();

    if(!token) {
        return <Navigate to="/" state={{ from: location}} replace />
    }
 return element

};

export default ProtectedRoute;