import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backButton from "../../assets/svg/backButton.svg";
import { Box, IconButton } from "@mui/material";
import { CapitalizeFirstLetter } from "../../CommonMethods";

const GoBack = ({ heading }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    if (location.state && location.state.from) {
      // Navigate to the page stored in state
      navigate(location.state.from, { replace: true });
    } else {
      // Default behavior if no previous page is stored in state
      navigate(-1);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <IconButton>
        <img
          style={{ cursor: "pointer" }}
          height={24}
          width={24}
          src={backButton}
          alt="back-img"
          onClick={handleGoBack}
        />
      </IconButton>
      <Box
        sx={{
          color: " var(--custom-primary)",
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        {CapitalizeFirstLetter(heading)}
      </Box>
    </Box>
  );
};

export default GoBack;
