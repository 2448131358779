import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const sessionToken = sessionStorage.getItem("token");

const initialState = {
  token: sessionToken || null,
  user: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      state.user = jwtDecode(action.payload);
      sessionStorage.setItem("token", action.payload);
    },
    clearToken: (state) => {
      state.token = null;
      state.user = null;
      sessionStorage.removeItem("token");
      localStorage.removeItem("persist:root");
    },
  },
});

export const { setToken, clearToken } = authSlice.actions;
export default authSlice.reducer;
