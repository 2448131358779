import { axiosPrivateCall } from "../../../utilis/Rauth/Portauth";

export const msData = async (email, name, designation, contactNum) => {
  try {
    const response = await axiosPrivateCall.get(
      `/login/validateSocialLogin?email=${email}&name=${name}&contactNum=${contactNum}&designation=${designation}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    } else {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};
