import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import HowToRegIcon from "@mui/icons-material/HowToRegOutlined";
import DragHandleIcon from "@mui/icons-material/DragHandleOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import blend from "../../assets/svg/blending.svg";
import FaqIcon from "../../assets/svg/faq.svg";
import QuestionMarkIcon from "../../assets/svg/questionMark.svg";
import { Typography, Hidden, SwipeableDrawer } from "@mui/material";
import "../../components/Menu/Menu.css";

const drawerWidth = 90;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  marginTop: "72px",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  marginTop: "72px",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Menu = ({ handleDrawerToggle, open }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggleMobile = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseDrawerMobile = () => {
    setMobileOpen(false);
  };

  const drawerContent = (
    <>
      <Hidden smDown>
        <DrawerHeader>
          <IconButton onClick={handleDrawerToggle}>
            <DragHandleIcon />
          </IconButton>
        </DrawerHeader>
      </Hidden>
      <Hidden smUp>
        <Box display={"flex"} justifyContent={"space-between"}>
          <DrawerHeader>
            <IconButton onClick={handleCloseDrawerMobile} sx={{ ml: "auto" }}>
              <MenuIcon />
            </IconButton>
          </DrawerHeader>
          <DrawerHeader>
            <IconButton onClick={handleCloseDrawerMobile} sx={{ ml: "auto" }}>
              <CloseIcon />
            </IconButton>
          </DrawerHeader>
        </Box>
      </Hidden>
      <List>
        <NavLink
          to="/home/dashboard"
          style={{ textDecoration: "none", color: "inherit" }}
          className={({ isActive }) => (isActive ? "active" : "")}
          onClick={handleCloseDrawerMobile}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 0,
                justifyContent: "center",
              }}
            >
              <DashboardIcon />
            </ListItemIcon>
            {open && (
              <Typography
                sx={{ fontWeight: 450 }}
                variant="body2"
                align="center"
              >
                Dashboard
              </Typography>
            )}
          </ListItemButton>
        </NavLink>
        <NavLink
          to="/home/leadManagement"
          style={{ textDecoration: "none", color: "inherit" }}
          className={({ isActive }) => (isActive ? "active" : "")}
          onClick={handleCloseDrawerMobile}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 0,
                justifyContent: "center",
              }}
            >
              <HowToRegIcon />
            </ListItemIcon>
            {open && (
              <Typography
                sx={{ fontWeight: 450 }}
                variant="body2"
                align="center"
              >
                Lead
                <br />
                Management
              </Typography>
            )}
          </ListItemButton>
        </NavLink>
      </List>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: "200px",
          backgroundImage: `url(${blend})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          mb: 9,
        }}
      >
        {open ? (
          <Box
            sx={{
              mt: 12.5,
            }}
          >
            <img
              width={92}
              height={92}
              src={FaqIcon}
              alt="FAQ icon"
              style={{ width: "100%" }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              mt: 12.5,
            }}
          >
            <img
              width={45}
              height={45}
              className={"questionMark"}
              src={QuestionMarkIcon}
              alt="QuestionMark icon"
              style={{ width: "100%" }}
            />
          </Box>
        )}
      </Box>
    </>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Hidden smDown>
        <Drawer variant="permanent" open={open}>
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden smUp>
        {!mobileOpen ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggleMobile}
            sx={{ ml: 0, mt: 1, justifyContent: "start" }}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <IconButton
            color="inherit"
            aria-label="close drawer"
            edge="start"
            onClick={handleCloseDrawerMobile}
            sx={{ ml: 0.3, mt: 1, justifyContent: "end" }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <SwipeableDrawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggleMobile}
          onOpen={handleDrawerToggleMobile}
          PaperProps={{
            sx: {
              width: "100%",
              top: "8%",
            },
          }}
        >
          {drawerContent}
        </SwipeableDrawer>
      </Hidden>
    </Box>
  );
};

export default Menu;
