import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "#416475",
        color: "#92a4ad",
        fontFamily: "'Montserrat', Helvetica, sans-serif",
      }}
    >
      <Box>
        <h1>404 Error Page</h1>

        <section className="error-container">
          <span>4</span>
          <span>
            <span className="screen-reader-text">0</span>
          </span>
          <span>4</span>
        </section>
        <div className="link-container">
          <a onClick={() => navigate("/home/dashboard")} className="more-link">
            Go to Home
          </a>
        </div>
      </Box>
    </Box>
  );
};

export default ErrorPage;
