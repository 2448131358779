import React, { useState, useEffect } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import GoBack from "../../components/GoBackButton";
import Input from "../../commonComponents/Input";
import SubHeading from "../../commonComponents/SubHeading";
import HorizontalLine from "../../commonComponents/HorizontalLine";
import Switch from "@mui/material/Switch";
import Add from "../../assets/svg/add.svg";
import mobileNumIcon from "../../assets/svg/PhoneLight.svg";
import locationIcon from "../../assets/svg/mapPointer.svg";
import dateIcon from "../../assets/svg/calendar.svg";
import arrowHorizontalIcon from "../../assets/svg/arrowHorizontal.svg";
import timeIcon from "../../assets/svg/timeLight.svg";
import percentage from "../../assets/svg/percentage.svg";
import moment from "moment";
import Button from "../../commonComponents/Button";
import feeIcon from "../../assets/svg/fee.svg";
import RadioButton from "../../commonComponents/RadioInput";
import Dropdown from "../../commonComponents/DropDown";
import ShowToast from "../../commonComponents/ShowToast";
import "./index.css";
import ImageUpload from "../../commonComponents/ImageUpload";
import useSnackbar from "../../hooks/useSnackbar";
import { useDynamicHeight } from "../../hooks/useDynamicHeight";
import { useSelector, useDispatch } from "react-redux";
import {
  getIndustryType,
  getStateAndCity,
  getLeadType,
  addLeadInfo,
  setPosition,
  setMyLocation,
  setStatus,
} from "../../redux/Slice/userStatus";
import { convertTime, timeDiffer, convertToISO } from "../../utilis/Date";
import { leadSchema } from "../../utilis/Validation";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import MonthYearPicker from "../../commonComponents/MonthYearPicker";
import axios from "axios";
import Loader from "../../components/Loader";
import dayjs from "dayjs";

const AddLead = () => {
  const userId = useSelector((state) => state.verification?.user?.id);
  const { industry, stateAndCity, leadType, position, myLocation } =
    useSelector((state) => state?.userStatusData || []);
  const [selectedImages, setSelectedImages] = useState([]);
  const [timeDifferences, setTimeDifferences] = useState([]);
  const [showValidationError, setShowValidationError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [addLead, setAddLead] = useState({
    userId: userId,
    companyName: "",
    industry: "",
    state: "",
    city: "",
    location: myLocation ? myLocation : null,
    lat: position?.latitude || "",
    lon: position?.longitude || "",
    contactPerson: [
      {
        id: 1,
        name: "",
        contact: "",
        mailId: "",
        designation: "",
      },
    ],
    paySlab: {
      type: "",
      signupMonth: "",
      avgSalary: "",
      existingNumber: "",
      sourcing: "",
      transfer: "",
      totplannedndNum: "",
      onetimeFee: "",
      otfamtType: "Flat amount",
      onetimeRevenue: "",
      monthlyFee: "",
      mfamtType: "Flat amount",
      calAmt: "",
      paymentTerms: "Collect & Pay",
      creditPeriod: "",
      creditLimit: "",
      salRevenue: "",
      payrollRevenue: "",
      toplineRevenue: "",
      notes: "",
    },
    status: "active",
    followUp: [
      {
        id: 1,
        date: moment().format("YYYY-MM-DD"),
        startTime: moment().format("HH:mm"),
        endTime: "",
        location: myLocation,
        virtualMeet: false,
        mobileNum: "",
      },
    ],
  });

  const dispatch = useDispatch();

  const {
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity = "success",
    showSnackbar,
    handleCloseSnackbar,
  } = useSnackbar();

  useEffect(() => {
    dispatch(getIndustryType());
    dispatch(getStateAndCity());
    dispatch(getLeadType());
  }, [dispatch]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          dispatch(setPosition({ latitude, longitude }));
          const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_LOCATION_API_KEY}`;

          axios
            .get(geocodeUrl)
            .then((response) => {
              if (response.data.results.length > 0) {
                dispatch(
                  setMyLocation(response.data.results[0].formatted_address)
                );
                setLoading(false);
              } else {
                setLoading(false);
                console.error("No address found for these coordinates.");
              }
            })
            .catch((error) => {
              setLoading(false);
              console.error("Error fetching address:", error);
            });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  useEffect(() => {
    const differences = addLead.followUp.map((followUp) => {
      if (followUp.startTime && followUp.endTime) {
        const diff = timeDiffer(followUp.endTime, followUp.startTime);
        if (diff > 0) {
          return `${Math.floor(diff / 60)} hrs ${diff % 60} mins`;
        } else {
          showSnackbar("End time must be after the start time.");
          return "";
        }
      } else {
        return "";
      }
    });
    setTimeDifferences(differences);
  }, [addLead.followUp]);

  useEffect(() => {
    const {
      avgSalary,
      sourcing,
      transfer,
      totplannedndNum,
      onetimeFee,
      otfamtType,
      monthlyFee,
      mfamtType,
    } = addLead?.paySlab;

    const averageSalary = parseFloat(avgSalary) || 0;
    // const totalPlannedNumber = parseFloat(totplannedndNum) || 0;
    const plannedSourcingNumber = parseFloat(sourcing) || 0;
    const plannedTransferNumber = parseFloat(transfer) || 0;

    // Calculate totalPlannedNumber correctly by summing sourcing and transfer
    const totalPlannedNumber = plannedSourcingNumber + plannedTransferNumber;

    setAddLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        totplannedndNum: totalPlannedNumber.toString(),
      },
    }));
    const onetimeFeeValue = parseFloat(onetimeFee) || 0;
    const monthlyFeeValue = parseFloat(monthlyFee) || 0;

    // Salary revenue calculation
    const salaryRevenue = averageSalary * totalPlannedNumber;

    // Payroll revenue calculation
    let payrollRevenue = 0;
    if (mfamtType === "Percentage") {
      payrollRevenue = (salaryRevenue * monthlyFeeValue) / 100;
    } else {
      // payrollRevenue =
      //   0.05 * averageSalary * (plannedSourcingNumber + plannedTransferNumber);
      payrollRevenue = monthlyFeeValue * totalPlannedNumber;
    }

    // One time revenue calculation
    let oneTimeRevenue = 0;
    if (otfamtType === "Percentage") {
      oneTimeRevenue =
        averageSalary * 12 * plannedSourcingNumber * (onetimeFeeValue / 100);
    } else {
      oneTimeRevenue = onetimeFeeValue * plannedSourcingNumber;
    }

    // Top line revenue calculation
    let topLineRevenue = salaryRevenue + payrollRevenue;
    if (plannedSourcingNumber > 0) {
      topLineRevenue += oneTimeRevenue;
    }

    // Monthly revenue calculation
    let monthlyRevenue = 0;
    if (mfamtType === "Flat amount") {
      monthlyRevenue = totalPlannedNumber * monthlyFeeValue;
    } else {
      monthlyRevenue =
        averageSalary * totalPlannedNumber * (monthlyFeeValue / 100);
    }

    setAddLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        salRevenue: salaryRevenue.toFixed(2),
        payrollRevenue: payrollRevenue.toFixed(2),
        toplineRevenue: topLineRevenue.toFixed(2),
        onetimeRevenue: oneTimeRevenue.toFixed(2),
        calAmt: monthlyRevenue.toFixed(2),
      },
    }));
  }, [
    addLead.paySlab.avgSalary,
    addLead.paySlab.existingNumber,
    addLead.paySlab.sourcing,
    addLead.paySlab.transfer,
    addLead.paySlab.totplannedndNum,
    addLead.paySlab.onetimeFee,
    addLead.paySlab.otfamtType,
    addLead.paySlab.monthlyFee,
    addLead.paySlab.mfamtType,
  ]);

  const handleAddContact = () => {
    if (addLead?.contactPerson.length < 10) {
      setAddLead((prevState) => ({
        ...prevState,
        contactPerson: [
          ...prevState.contactPerson,
          {
            id: prevState.contactPerson.length + 1,
            name: "",
            contact: "",
            mailId: "",
            designation: "",
          },
        ],
      }));
    } else {
      showSnackbar("You cannot add more than 10 secondary contacts.");
    }
  };

  const handleCompanyNameChange = (event) => {
    setAddLead((prevState) => ({
      ...prevState,
      companyName: event.target.value,
    }));
  };

  const handleIndustryChange = (newValue) => {
    setAddLead((prevState) => ({
      ...prevState,
      industry: newValue,
    }));
  };

  const handleStateChange = (newValue) => {
    setAddLead((prevState) => ({
      ...prevState,
      state: newValue,
    }));
  };

  const handleCityChange = (newValue) => {
    setAddLead((prevState) => ({
      ...prevState,
      city: newValue,
    }));
  };

  const handleTypeChange = (newValue) => {
    setAddLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        type: newValue,
      },
    }));
  };

  const handleSignUpMonthChange = (newValue) => {
    const formattedDate = newValue ? dayjs(newValue).format("MMMM YYYY") : "";
    setAddLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        signupMonth: formattedDate,
      },
    }));
  };

  const handleOneTimeFeeRadioButtonChange = (event) => {
    setAddLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        otfamtType: event.target.value,
      },
    }));
  };

  const handleMonthlyFeeRadioButtonChange = (event) => {
    setAddLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        mfamtType: event.target.value,
      },
    }));
  };

  const handleCollectAndPayChange = (event) => {
    setAddLead((prevState) => ({
      ...prevState,
      paySlab: {
        ...prevState.paySlab,
        paymentTerms: event.target.value,
      },
    }));
  };

  const handleContactChange = (index, field, value) => {
    const newContacts = [...addLead.contactPerson];
    newContacts[index][field] = value;
    setAddLead((prevState) => ({
      ...prevState,
      contactPerson: newContacts,
    }));
  };

  const handleAddFollowUp = () => {
    if (addLead?.followUp.length < 10) {
      setAddLead((prevState) => ({
        ...prevState,
        followUp: [
          ...prevState.followUp,
          {
            id:
              prevState.followUp.length > 0 ? prevState.followUp.length + 1 : 1,
            date: moment().format("YYYY-MM-DD"),
            startTime: moment().format("HH:mm"),
            endTime: "",
            location: myLocation || "",
            currentLocation: "",
            virtualMeet: false,
            mobileNum: "",
          },
        ],
      }));
    } else {
      showSnackbar("You cannot add more than 10 follow-ups.");
    }
  };

  const handleFollowUpChange = (index, field, value) => {
    const newFollowUps = [...addLead.followUp];
    const followUp = newFollowUps[index];

    if (field === "startTime" || field === "endTime") {
      const currentDate = moment().format("YYYY-MM-DD");
      const currentTime = moment().format("HH:mm");

      if (field === "startTime") {
        if (
          followUp.date === currentDate &&
          moment(value, "HH:mm").isBefore(moment(currentTime, "HH:mm"))
        ) {
          showSnackbar("Start time cannot be before the current time.");
          return;
        }
      }

      if (
        field === "endTime" &&
        moment(value, "HH:mm").isSameOrBefore(
          moment(followUp.startTime, "HH:mm")
        )
      ) {
        showSnackbar("End time must be greater than the start time.");
        newFollowUps[index][field] = ""; // Reset endTime
      } else {
        newFollowUps[index][field] = value;
      }
    } else {
      newFollowUps[index][field] = value;
    }

    setAddLead((prevState) => ({
      ...prevState,
      followUp: newFollowUps,
    }));
  };

  const handleAddLeadSubmit = async () => {
    try {
      setShowValidationError(false);
      setShowValidationError(true);
      await leadSchema.validate(addLead, { abortEarly: false });
      const leadData = JSON.parse(JSON.stringify(addLead));

      leadData.followUp = leadData.followUp
        .filter((followUp) => followUp.endTime)
        .map(({ id, date, startTime, endTime, ...rest }) => ({
          ...rest,
          date: date,
          startTime: startTime ? convertToISO(startTime, date) : "",
          endTime: endTime ? convertToISO(endTime, date) : "",
        }));

      leadData.contactPerson = leadData.contactPerson.map(
        ({ id, ...rest }) => rest
      );

      leadData.status = "active";

      dispatch(addLeadInfo(selectedImages, leadData));
      showSnackbar("Lead submitted successfully", "success");
      handleReset();
      setTimeout(() => {
        navigate("/home/leadManagement");
        dispatch(setStatus("active"));
      }, 500);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setShowValidationError(true);
        err.inner.reverse().forEach((error) => {
          showSnackbar(error.message);
        });
      }
    }
  };

  const handleSaveDraft = async () => {
    try {
      await Yup.object()
        .shape({
          companyName: Yup.string().required("Company name is required"),
        })
        .validate(addLead, { abortEarly: false });

      const leadData = JSON.parse(JSON.stringify(addLead));

      leadData.followUp = leadData.followUp
        .filter((followUp) => followUp.endTime)
        .map(({ id, date, startTime, endTime, ...rest }) => ({
          ...rest,
          date: date,
          startTime: startTime ? convertToISO(startTime, date) : "",
          endTime: endTime ? convertToISO(endTime, date) : "",
        }));

      leadData.contactPerson = leadData.contactPerson.map(
        ({ id, ...rest }) => rest
      );

      leadData.status = "draft";

      dispatch(addLeadInfo(selectedImages, leadData));
      showSnackbar("Draft saved successfully", "success");
      handleReset();
      setTimeout(() => {
        navigate("/home/leadManagement");
        dispatch(setStatus("draft"));
      }, 500);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.reverse().forEach((error) => {
          showSnackbar(error.message);
        });
      }
    }
  };

  const handleReset = () => {
    setSelectedImages([]);
    setAddLead({
      userId: userId,
      companyName: "",
      industry: "",
      state: "",
      city: "",
      contactPerson: [
        {
          name: "",
          contact: "",
          mailId: "",
          designation: "",
        },
      ],
      paySlab: {
        type: "",
        signupMonth: "",
        avgSalary: "",
        existingNumber: "",
        sourcing: "",
        transfer: "",
        totplannedndNum: "",
        onetimeFee: "",
        otfamtType: "Flat amount",
        onetimeRevenue: "",
        monthlyFee: "",
        mfamtType: "Flat amount",
        calAmt: "",
        paymentTerms: "Collect & Pay",
        creditPeriod: "",
        creditLimit: "",
        salRevenue: "",
        payrollRevenue: "",
        toplineRevenue: "",
        notes: "",
      },
      status: "active",
      followUp: [
        {
          id: 1,
          date: "",
          startTime: "",
          endTime: "",
          location: "",
          virtualMeet: false,
          mobileNum: "",
        },
      ],
    });
  };

  const screenSize = useDynamicHeight();
  const height = screenSize.dynamicHeight * 0.736;

  return (
    <Card
      className={"scrollbar-hidden"}
      sx={{
        backgroundColor: "#ffffff",
        height: height,
        overflow: "auto",
      }}
    >
      <GoBack heading={"Add Lead"} />
      {loading ? (
        <Loader height={"63.2vh"} />
      ) : (
        <Box sx={{ m: 2, lineHeight: 2.5 }}>
          {/**Lead Information */}
          <Box>
            <SubHeading text={"Lead Information"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                label={"Company Name"}
                placeholder={"Enter Company Name"}
                showAsterisk={true}
                onChange={handleCompanyNameChange}
                value={addLead?.companyName}
                maxLength={100}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Dropdown
                label={"Industry"}
                placeholder={"Choose industry type"}
                value={addLead?.industry}
                onChange={handleIndustryChange}
                options={industry.map((ind) => ({
                  value: ind.label,
                  label: ind.label,
                }))}
                showAsterisk={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Dropdown
                label={"State"}
                placeholder={"Select State"}
                value={addLead?.state}
                onChange={handleStateChange}
                options={stateAndCity.map((state) => ({
                  value: state.name,
                  label: state.name,
                }))}
                showAsterisk={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Dropdown
                label={"City"}
                placeholder={"Select City"}
                value={addLead?.city}
                onChange={handleCityChange}
                options={
                  addLead?.state
                    ? stateAndCity
                        .find((state) => state.name === addLead?.state)
                        ?.districts.map((city) => ({
                          value: city,
                          label: city,
                        }))
                    : []
                }
                showAsterisk={true}
              />
            </Grid>
          </Grid>
          <ImageUpload
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
          />
          {/**Contact Person */}
          <Box>
            <SubHeading marginTop={1.5} text={"Contact Person"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          {/**Primary Contact */}
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: 500,
              color: " #505259",
            }}
          >
            {"Primary Contact"}
          </Typography>
          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"text"}
                label={"Name"}
                placeholder={"Enter Name"}
                showAsterisk={true}
                value={addLead?.contactPerson[0].name}
                onChange={(e) => handleContactChange(0, "name", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"mobileNumber"}
                label={"Contact"}
                placeholder={"Enter Contact"}
                showAsterisk={true}
                value={addLead?.contactPerson[0].contact}
                onChange={(e) =>
                  handleContactChange(0, "contact", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"emai"}
                label={"Mail ID"}
                placeholder={"Enter Mail ID"}
                showAsterisk={true}
                value={addLead?.contactPerson[0].mailId}
                onChange={(e) =>
                  handleContactChange(0, "mailId", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"text"}
                label={"Designation "}
                placeholder={"Enter Designation"}
                showAsterisk={true}
                value={addLead?.contactPerson[0].designation}
                onChange={(e) =>
                  handleContactChange(0, "designation", e.target.value)
                }
              />
            </Grid>
          </Grid>
          {/**Secondary Contact */}
          {addLead?.contactPerson &&
            addLead?.contactPerson.length > 0 &&
            addLead?.contactPerson.slice(1).map((contact, index) => (
              <React.Fragment key={contact.id}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: " #505259",
                    marginTop: index > 0 ? "16px" : "0",
                  }}
                >
                  {`Secondary Contact ${index + 1}`}
                </Typography>
                <Grid container spacing={1.8}>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Input
                      type={"text"}
                      label={"Name"}
                      placeholder={"Enter Name"}
                      showAsterisk={true}
                      value={contact.name}
                      onChange={(e) =>
                        handleContactChange(index + 1, "name", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Input
                      type={"mobileNumber"}
                      label={"Contact"}
                      placeholder={"Enter Contact"}
                      showAsterisk={true}
                      value={contact.contact}
                      onChange={(e) =>
                        handleContactChange(
                          index + 1,
                          "contact",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Input
                      label={"Mail Id"}
                      placeholder={"Enter Mail Id"}
                      showAsterisk={true}
                      value={contact.mailId}
                      onChange={(e) =>
                        handleContactChange(index + 1, "mailId", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Input
                      type={"text"}
                      label={"Designation"}
                      placeholder={"Enter Designation"}
                      showAsterisk={true}
                      value={contact.designation}
                      onChange={(e) =>
                        handleContactChange(
                          index + 1,
                          "designation",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}

          <Button
            text={"Add Contact"}
            startIcon={<img src={Add} alt={"Add icon"} />}
            color={"#0e3973"}
            borderColor={"#0e3973"}
            variant={"outlined"}
            onClick={handleAddContact}
            // disabled={contacts.length >= 10}
          />
          <ShowToast
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          />
          {/**Lead Status */}
          <Box>
            <SubHeading marginTop={1.5} text={"Lead Status"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Dropdown
                label={"Type"}
                placeholder={"Choose Type"}
                value={addLead?.paySlab?.type}
                onChange={handleTypeChange}
                options={leadType.map((lead) => ({
                  value: lead.name,
                  label: lead.name,
                }))}
                showAsterisk={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <MonthYearPicker
                label="Sign up month"
                showAsterisk
                value={addLead?.paySlab?.signupMonth}
                onChange={handleSignUpMonthChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"decimal"}
                label={"Average Salary"}
                placeholder={"Enter Average Salary"}
                value={addLead?.paySlab?.avgSalary}
                onChange={(e) =>
                  setAddLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      avgSalary: e.target.value,
                    },
                  }))
                }
                showAsterisk={true}
              />
            </Grid>
          </Grid>
          {/**Existing Number */}
          <Box>
            <SubHeading marginTop={1.5} text={"Existing Number"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Input
                type={"decimal"}
                label={"Existing Number"}
                placeholder={"Enter Existing Number"}
                value={addLead?.paySlab?.existingNumber}
                onChange={(e) =>
                  setAddLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      existingNumber: e.target.value,
                    },
                  }))
                }
                showAsterisk={true}
              />
            </Grid>
          </Grid>
          {/**Planned Number */}
          <Box>
            <SubHeading
              marginTop={1.5}
              text={"Planned Number"}
              subText={"(Any one required)"}
            />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          <Box>
            <Grid container spacing={1.8}>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <Input
                  type={"decimal"}
                  label={"Sourcing"}
                  placeholder={"Enter Sourcing"}
                  value={addLead?.paySlab?.sourcing}
                  onChange={(e) =>
                    setAddLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        sourcing: e.target.value,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <Input
                  type={"decimal"}
                  label={"Transfer"}
                  placeholder={"Enter Transfer"}
                  value={addLead?.paySlab?.transfer}
                  onChange={(e) =>
                    setAddLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        transfer: e.target.value,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={6}
                lg={6}
                xl={6}
                display={"flex"}
                justifyContent={"end"}
              >
                <Input
                  type={"number"}
                  startIcon={"₹"}
                  disabled={true}
                  label={"Total Number"}
                  placeholder={"800"}
                  value={addLead?.paySlab?.totplannedndNum}
                  onChange={(e) =>
                    setAddLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        totplannedndNum: e.target.value,
                      },
                    }))
                  }
                  backgroundColor={"#F6F6F6"}
                />
              </Grid>
            </Grid>
          </Box>
          {/**Sourcing */}
          <Box>
            <SubHeading marginTop={1.5} text={"Sourcing"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={1.8} alignItems={"center"}>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Input
                  type={
                    addLead?.paySlab?.otfamtType === "Flat amount"
                      ? "decimal"
                      : "number"
                  }
                  label={"One time fee"}
                  placeholder={"Enter Amount"}
                  value={addLead?.paySlab?.onetimeFee}
                  maxLength={
                    addLead?.paySlab?.otfamtType === "Flat amount" ? "" : 5
                  }
                  onChange={(e) =>
                    setAddLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        onetimeFee: e.target.value,
                      },
                    }))
                  }
                  endIcon={
                    addLead?.paySlab?.otfamtType === "Flat amount" ? (
                      <img src={feeIcon} alt="fee icon" />
                    ) : (
                      <img src={percentage} alt="percentage icon" />
                    )
                  }
                />
              </Grid>
              <Grid marginTop={1.5} item xs={6} sm={3} md={2} lg={1.5} xl={1}>
                <RadioButton
                  label={"Flat amount"}
                  value={"Flat amount"}
                  onChange={handleOneTimeFeeRadioButtonChange}
                  checked={addLead?.paySlab?.otfamtType === "Flat amount"}
                />
              </Grid>
              <Grid marginTop={1.5} item xs={6} sm={3} md={2} lg={1.5} xl={1}>
                <RadioButton
                  label="Percentage"
                  value={"Percentage"}
                  onChange={handleOneTimeFeeRadioButtonChange}
                  checked={addLead?.paySlab?.otfamtType === "Percentage"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={5}
                lg={6}
                xl={7}
                display={"flex"}
                justifyContent={"end"}
              >
                <Input
                  disabled={true}
                  startIcon={"₹"}
                  type={"number"}
                  label={"One time Revenue"}
                  placeholder={"₹ 50,000"}
                  value={addLead?.paySlab?.onetimeRevenue}
                  onChange={(e) =>
                    setAddLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        onetimeRevenue: e.target.value,
                      },
                    }))
                  }
                  backgroundColor={"#F6F6F6"}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={1.8} alignItems="center">
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Input
                  showAsterisk={true}
                  type={
                    addLead?.paySlab?.mfamtType === "Flat amount"
                      ? "decimal"
                      : "number"
                  }
                  label={"Monthly fee"}
                  placeholder={"Enter Amount"}
                  value={addLead?.paySlab?.monthlyFee}
                  maxLength={
                    addLead?.paySlab?.mfamtType === "Flat amount" ? "" : 5
                  }
                  onChange={(e) =>
                    setAddLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        monthlyFee: e.target.value,
                      },
                    }))
                  }
                  endIcon={
                    addLead?.paySlab?.mfamtType === "Flat amount" ? (
                      <img src={feeIcon} alt="fee icon" />
                    ) : (
                      <img src={percentage} alt="percentage icon" />
                    )
                  }
                />
              </Grid>
              <Grid marginTop={1.5} item xs={6} sm={3} md={2} lg={1.5} xl={1}>
                <RadioButton
                  label={"Flat amount"}
                  value={"Flat amount"}
                  onChange={handleMonthlyFeeRadioButtonChange}
                  checked={addLead?.paySlab?.mfamtType === "Flat amount"}
                />
              </Grid>
              <Grid marginTop={1.5} item xs={6} sm={3} md={2} lg={1.5} xl={1}>
                <RadioButton
                  label="Percentage"
                  value={"Percentage"}
                  onChange={handleMonthlyFeeRadioButtonChange}
                  checked={addLead?.paySlab?.mfamtType === "Percentage"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={5}
                lg={6}
                xl={7}
                display={"flex"}
                justifyContent={"end"}
              >
                <Input
                  disabled={true}
                  startIcon={"₹"}
                  type={"number"}
                  label={"Monthly Revenue"}
                  placeholder={"₹ 30,000"}
                  value={addLead?.paySlab?.calAmt}
                  onChange={(e) =>
                    setAddLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        calAmt: e.target.value,
                      },
                    }))
                  }
                  backgroundColor={"#F6F6F6"}
                />
              </Grid>
            </Grid>
          </Box>

          {/**Payment Terms */}
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: 500,
              color: " #505259",
            }}
          >
            {"Payment Terms"}
          </Typography>
          <Grid container marginBottom={1} spacing={1.8}>
            <Grid item xs={6} sm={3} md={1.8} lg={1.4} xl={1.4}>
              <RadioButton
                label={"Collect & Pay"}
                value={"Collect & Pay"}
                onChange={handleCollectAndPayChange}
                checked={addLead?.paySlab?.paymentTerms === "Collect & Pay"}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={1.8} lg={1.4} xl={1.4}>
              <RadioButton
                label="Pay & Collect"
                value={"Pay & Collect"}
                onChange={handleCollectAndPayChange}
                checked={addLead?.paySlab?.paymentTerms === "Pay & Collect"}
              />
            </Grid>
          </Grid>
          {addLead?.paySlab?.paymentTerms === "Pay & Collect" && (
            <Grid container spacing={1.8}>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Input
                  type={"decimal"}
                  label={"Credit Period"}
                  placeholder={"Enter No Of Days"}
                  value={addLead?.paySlab?.creditPeriod}
                  onChange={(e) =>
                    setAddLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        creditPeriod: e.target.value,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Input
                  type={"decimal"}
                  label={"Credit Limit"}
                  placeholder={"₹ Enter amount"}
                  value={addLead?.paySlab?.creditLimit}
                  onChange={(e) =>
                    setAddLead((prevState) => ({
                      ...prevState,
                      paySlab: {
                        ...prevState.paySlab,
                        creditLimit: e.target.value,
                      },
                    }))
                  }
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Input
                disabled={true}
                startIcon={"₹"}
                type={"number"}
                label={"Salary Revenue"}
                placeholder={"₹ 8,00,000"}
                value={addLead?.paySlab?.salRevenue}
                onChange={(e) =>
                  setAddLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      salRevenue: e.target.value,
                    },
                  }))
                }
                backgroundColor={"#F6F6F6"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Input
                disabled={true}
                startIcon={"₹"}
                type={"number"}
                label={"Payroll Revenue"}
                placeholder={"₹ 8,00,000"}
                value={addLead?.paySlab?.payrollRevenue}
                onChange={(e) =>
                  setAddLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      payrollRevenue: e.target.value,
                    },
                  }))
                }
                backgroundColor={"#F6F6F6"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Input
                disabled={true}
                startIcon={"₹"}
                type={"number"}
                label={"Top Line Revenue"}
                placeholder={"₹ 8,00,000"}
                value={addLead?.paySlab?.toplineRevenue}
                onChange={(e) =>
                  setAddLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      toplineRevenue: e.target.value,
                    },
                  }))
                }
                backgroundColor={"#F6F6F6"}
              />
            </Grid>
          </Grid>
          {/**Notes */}
          <Box>
            <SubHeading marginTop={1.5} text={"Notes"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>

          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: 500,
              color: " #505259",
            }}
          >
            {"Notes"}
          </Typography>

          <Grid container spacing={1.8}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                value={addLead?.paySlab?.notes}
                onChange={(e) =>
                  setAddLead((prevState) => ({
                    ...prevState,
                    paySlab: {
                      ...prevState.paySlab,
                      notes: e.target.value,
                    },
                  }))
                }
                fullWidth
              />
            </Grid>
          </Grid>

          {/**Schedule Follow-ups */}
          <Box>
            <SubHeading marginTop={1.5} text={"Schedule Follow-ups"} />
            <HorizontalLine marginBottom={1.6} />
          </Box>
          {addLead?.followUp &&
            addLead?.followUp.map((followUp, index) => (
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: " #505259",
                    marginTop: index > 0 ? "16px" : "0",
                    mb: 1.5,
                  }}
                >
                  {`Add Follow-up ${followUp.id}`}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item>
                    <img
                      style={{
                        backgroundColor: "#EDEDEE",
                        padding: "2.4px 2.3px",
                        borderRadius: "2px",
                      }}
                      src={dateIcon}
                      alt={"date icon"}
                    />
                  </Grid>
                  <Grid item xs>
                    <Input
                      className="date-picker"
                      type="date"
                      value={followUp?.date}
                      onChange={(e) =>
                        handleFollowUpChange(index, "date", e.target.value)
                      }
                      min={moment().format("YYYY-MM-DD")}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    <img
                      style={{
                        backgroundColor: "#EDEDEE",
                        padding: "4.5px 4.5px",
                        borderRadius: "2px",
                      }}
                      src={timeIcon}
                      alt={"time icon"}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={1.2} lg={1.2} xl={1.2}>
                    <Input
                      type="time"
                      value={followUp.startTime}
                      onChange={(e) =>
                        handleFollowUpChange(index, "startTime", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <img src={arrowHorizontalIcon} alt={"Arrow icon"} />
                  </Grid>
                  <Grid item xs={4} sm={4} md={1.2} lg={1.2} xl={1.2}>
                    <Input
                      type="time"
                      value={followUp.endTime}
                      onChange={(e) =>
                        handleFollowUpChange(index, "endTime", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1.1}
                    sm={1.1}
                    md={1.1}
                    lg={1.1}
                    xl={1.1}
                    marginTop={0.6}
                  >
                    <Typography sx={{ fontSize: 13, color: "#73757A" }}>
                      {timeDifferences[index]}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    <img
                      style={{
                        backgroundColor: "#EDEDEE",
                        padding: "4.5px 7px",
                        borderRadius: "2px",
                      }}
                      src={locationIcon}
                      alt={"location icon"}
                    />
                  </Grid>
                  <Grid item xs>
                    <Input
                      type="location"
                      placeholder="Add location"
                      value={followUp.location}
                      onChange={(e) =>
                        handleFollowUpChange(index, "location", e.target.value)
                      }
                      maxLength={30}
                      endIcon={
                        <Typography
                          sx={{ color: " var(--secondary)", fontSize: "13px" }}
                          component="label"
                        >
                          Virtual Meeting
                          <Switch
                            sx={{
                              ml: 1,
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: "#103b75",
                                "&:hover": {
                                  backgroundColor: "rgba(16, 59, 117, 0.08)",
                                },
                              },
                              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                {
                                  backgroundColor: "#103b75",
                                },
                            }}
                            checked={followUp.virtualMeet}
                            onChange={(e) =>
                              handleFollowUpChange(
                                index,
                                "virtualMeet",
                                e.target.checked
                              )
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    <img
                      style={{
                        backgroundColor: "#EDEDEE",
                        padding: "2.5px 2.3px",
                        borderRadius: "2px",
                      }}
                      src={mobileNumIcon}
                      alt={"mobile icon"}
                    />
                  </Grid>
                  <Grid item xs>
                    <Input
                      type={"mobileNumber"}
                      placeholder="Mobile Number"
                      value={followUp.mobileNum}
                      onChange={(e) =>
                        handleFollowUpChange(index, "mobileNum", e.target.value)
                      }
                      maxLength={30}
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
          <Box sx={{ mt: 1, mb: 2 }}>
            <Button
              text={"Add Follow-ups"}
              startIcon={<img src={Add} alt={"Add icon"} />}
              color={"#0e3973"}
              borderColor={"#0e3973"}
              variant={"outlined"}
              onClick={handleAddFollowUp}
            />
          </Box>
          <Grid
            sx={{ display: "flex", justifyContent: "end", gap: 2 }}
            container
          >
            <Button
              px={10}
              text={"Reset"}
              color={"#E7554A"}
              borderColor={"#F9D5D2"}
              variant={"outlined"}
              onClick={handleReset}
            />
            <Button
              px={7.2}
              text={"Save As Draft"}
              color={"#3771C3"}
              borderColor={"#3771C3"}
              variant={"outlined"}
              onClick={handleSaveDraft}
            />
            <Button
              px={9.6}
              text={"Submit"}
              color={"#ffffff"}
              borderColor={"#3771C3)"}
              background={"var(--gradient1)"}
              variant={"outlined"}
              onClick={handleAddLeadSubmit}
            />
          </Grid>
          {showValidationError && (
            <Box
              sx={{
                textAlign: "end",
                fontSize: "13px",
                fontWeight: 500,
                color: "#DE1F1F",
              }}
            >
              *Please fill out all the required fields to create a Lead
            </Box>
          )}
        </Box>
      )}
    </Card>
  );
};

export default AddLead;
