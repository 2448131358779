import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  from: {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  },
  to: {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  },
};

const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    setFromMonth: (state, action) => {
      state.from.month = action.payload;
    },
    setFromYear: (state, action) => {
      state.from.year = action.payload;
    },
    setToMonth: (state, action) => {
      state.to.month = action.payload;
    },
    setToYear: (state, action) => {
      state.to.year = action.payload;
    },
  },
});

export const {
  setFromMonth,
  setFromYear,
  setToMonth,
  setToYear,
} = dateSlice.actions;

export default dateSlice.reducer;
