import { useEffect } from "react";

const NetworkStatus = ({ setIsOffline }) => {
  const handleOffline = () => setIsOffline(true);
  const handleOnline = () => setIsOffline(false);

  useEffect(() => {
    setIsOffline(!navigator.onLine); // Set initial status
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [setIsOffline]);

  return null;
};

export default NetworkStatus;
