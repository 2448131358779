import moment from 'moment-timezone'; // Import moment-timezone for timezone handling

export const convertDateTimeIst = (inputDateTime) => {
    if (!inputDateTime) {
        return '-';
    }
    // Convert inputDateTime to Indian Standard Time (IST)
    const indianDateTime = moment(inputDateTime).tz('Asia/Kolkata');

    return indianDateTime.format('hh:mm A'); // Use 'hh:mm A' for 12-hour format
}