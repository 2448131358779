import React from "react";
import { Box, TextField, Typography, InputAdornment } from "@mui/material";

const Input = ({
  className,
  placeholder,
  type,
  value,
  onChange,
  label,
  backgroundColor,
  showAsterisk = false,
  startIcon,
  endIcon,
  disabled = false,
  min,
  inputPadding,
  maxLength,
}) => {
  const handleChange = (e) => {
    let inputValue = e.target.value;

    // Regex to match all emojis and remove them
    inputValue = inputValue.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u26FF]|\uD83C[\uDDE6-\uDDFF]|\uD83D[\uDC00-\uDE4F]|\uD83D[\uDE80-\uDEF6]|\uD83E[\uDD00-\uDDFF])/g,
      ""
    );

    if (maxLength && inputValue.length > maxLength) return;

    if (type === "mobileNumber") {
      if (/^\d{0,10}$/.test(inputValue)) {
        onChange({ ...e, target: { ...e.target, value: inputValue } });
      }
    } else if (type === "decimal") {
      if (/^\d*$/.test(inputValue)) {
        onChange({ ...e, target: { ...e.target, value: inputValue } });
      }
    } else if (type === "number") {
      if (/^\d*\.?\d*$/.test(inputValue)) {
        if (maxLength && parseFloat(inputValue) > 100) return;
        onChange({ ...e, target: { ...e.target, value: inputValue } });
      }
    } else if (type === "textWithSymbols") {
      if (/^[^\d]*$/.test(inputValue)) {
        onChange({ ...e, target: { ...e.target, value: inputValue } });
      }
    } else if (type === "text") {
      if (/^[a-zA-Z\s]*$/.test(inputValue)) {
        onChange({ ...e, target: { ...e.target, value: inputValue } });
      }
    } else if (type === "location") {
      inputValue = inputValue.replace(/[^a-zA-Z0-9,/\s]/g, "");
      onChange({ ...e, target: { ...e.target, value: inputValue } });
    } else {
      onChange({ ...e, target: { ...e.target, value: inputValue } });
    }
  };

  const handleFocus = (e) => {
    if (type === "date" || type === "time") {
      e.target.showPicker();
    }
  };

  return (
    <Box>
      <Typography
        variant="body1"
        sx={{
          fontSize: "14px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "var(--secondary)",
        }}
      >
        {label}
        {showAsterisk && (
          <span style={{ color: "var(--custom-primary)" }}> *</span>
        )}
      </Typography>
      <Box>
        <TextField
          disabled={disabled}
          className={className}
          placeholder={placeholder}
          type={type === "mobileNumber" || type === "number" ? "text" : type}
          value={value}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          maxLength={maxLength}
          InputProps={{
            inputMode: type === "mobileNumber" ? "numeric" : undefined,
            pattern: type === "mobileNumber" ? "\\d{0,10}" : undefined,
            startAdornment: startIcon ? (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ) : null,
            endAdornment: endIcon ? (
              <InputAdornment position="end">{endIcon}</InputAdornment>
            ) : null,
            sx: {
              padding: 0.8,
              fontSize: "13px",
              fontWeight: 400,
              color: "var(--secondary)",
              backgroundColor: backgroundColor,
              borderRadius: "4px",
              outline: 0,
              "&:hover": {
                borderColor: "#DCDCDE",
              },
              "& .MuiInputBase-input": {
                padding: "0",
                paddingRight: inputPadding ? inputPadding : "0",
                "&[type=number]::-webkit-outer-spin-button, &[type=number]::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "&[type=number]": {
                  MozAppearance: "textfield",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#DCDCDE",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#DCDCDE",
              },
              "& .MuiInputBase-input:focus": {
                outline: "none",
              },
              "&::placeholder": {
                color: "#707070",
                opacity: 0.7,
                fontSize: "13px",
              },
            },
          }}
          inputProps={{
            min: min,
            onKeyDown:
              type === "date" || type === "time"
                ? (e) => e.preventDefault()
                : undefined,
            onPaste:
              type === "date" || type === "time"
                ? (e) => e.preventDefault()
                : undefined,
            onFocus: handleFocus,
          }}
        />
      </Box>
    </Box>
  );
};

export default Input;
