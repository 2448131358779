import LogoutIcon from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import bellIcon from "../../assets/svg/bellNav.svg";
import budgieLogo from "../../assets/svg/budgieLogo.svg";
import profile from "../../assets/svg/hero.jpeg";
import { clearToken } from "../../redux/Slice/authSlice";
import { persistor } from "../../redux/Store/Store";
import styles from "./navbar.module.css";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    dispatch(clearToken());
    persistor.purge();
  };
  const handleCardClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={styles.navmain}>
      <div className={styles.navlogo}>
        <img src={budgieLogo} alt="logo" />
      </div>
      <div className={styles.navavatar}>
        <img src={bellIcon} alt="bell icon" />
        <Divider
          color="#FFFFFF"
          orientation="vertical"
          sx={{ height: "30px", marginRight: "4px" }}
        />
        <Avatar
          alt=""
          src={profile}
          style={{ cursor: "pointer" }}
          className={styles.hero}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCardClose}
          className={styles.menuLogout}
        >
          <div
            className={styles.logoutButton}
            onClick={handleClose}
            onMouseLeave={handleCardClose}
          >
            <div className={styles.logoutItem}>
              <LogoutIcon />
              Logout
            </div>
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default Navbar;
