import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDynamicHeight } from "../../hooks/useDynamicHeight";
import { Card } from "@mui/material";

const tableStyle = {
  backgroundColor: "#EEF5FC",
  position: "sticky",
  top: 0,
  zIndex: 1,
};

const DynamicTable = ({ prospectData, clientData, xLabels, variant }) => {
  const screenSize = useDynamicHeight();
  const tableHeight = screenSize.dynamicHeight * 0.675;

  return (
    <Card sx={{ width: "95%" }}>
      <TableContainer
        className={"scrollbar-hidden"}
        sx={{ height: tableHeight }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={tableStyle}>Month</TableCell>
              <TableCell sx={tableStyle} align="left">
                {variant === "prospect-client" ? "Prospect" : "Target"}
              </TableCell>
              <TableCell sx={tableStyle} align="left">
                {variant === "prospect-client" ? "Client" : "Achieved"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {xLabels.map((label, index) => (
              <TableRow key={label}>
                <TableCell component="th" scope="row">
                  {label}
                </TableCell>
                <TableCell align="left">{prospectData[index]}</TableCell>
                <TableCell align="left">{clientData[index]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default DynamicTable;
