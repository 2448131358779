import { configureStore, combineReducers } from '@reduxjs/toolkit';
import timeReducer from '../Slice/timeSlice.js';
import userStatusReducer from '../Slice/userStatus.js';
import storage from 'redux-persist/lib/storage';
import {
  persistStore, persistReducer, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import authSlice from '../Slice/authSlice.js';
import dashboardData from '../Slice/dashboardData.js';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const rootReducer = combineReducers({ 
  date: timeReducer, 
  verification: authSlice,
  userStatusData: userStatusReducer,
  dashboard: dashboardData 
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: {
        ignoredPaths: ['userStatusData.largeDataArray'], // Adjust according to your state structure
      },
    });

    return middlewares;
  },
});

export const persistor = persistStore(store);
