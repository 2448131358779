import { createSlice } from "@reduxjs/toolkit";
import { axiosPrivateCall } from "../../utilis/Rauth/Portauth";

const initialState = {
  userStatus: [],
  leadId: "",
  logActivity: [],
  followUpHistory: [],
  noDataFound: false,
  createdDate: "",
  companyName: "",
  industry: [],
  stateAndCity: [],
  leadType: [],
  followUpData: [],
  leadData: [],
  editLeadData: [],
  status: "active",
  position: { latitude: null, longitude: null },
  myLocation: "",
  addedLeadLatitude: null,
  addedLeadLongitude: null,
};

const userStatusSlice = createSlice({
  name: "userStatusData",
  initialState,
  reducers: {
    updateUserStatus: (state, action) => {
      state.userStatus = action.payload;
      state.noDataFound = false;
    },
    setLeadId: (state, action) => {
      state.leadId = action.payload;
    },
    setLogActivity: (state, action) => {
      state.logActivity = action.payload;
    },
    setFollowUpHistory: (state, action) => {
      state.followUpHistory = action.payload;
    },
    setNoDataFound(state, action) {
      state.noDataFound = action.payload;
    },
    setCreatedDate(state, action) {
      state.createdDate = action.payload;
    },
    setCompanyName(state, action) {
      state.companyName = action.payload;
    },
    setIndustryName(state, action) {
      state.industry = action.payload;
    },
    setStateAndCity(state, action) {
      state.stateAndCity = action.payload;
    },
    setLeadType(state, action) {
      state.leadType = action.payload;
    },
    setFollowUpLead(state, action) {
      state.followUpData = action.payload;
    },
    setLeadData(state, action) {
      state.leadData = action.payload;
    },
    setEditLeadData(state, action) {
      state.editLeadData = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setPosition(state, action) {
      state.position = action.payload;
    },
    setMyLocation(state, action) {
      state.myLocation = action.payload;
    },
    setAddedLeadLatitude(state, action) {
      state.addedLeadLatitude = action.payload;
    },
    setAddedLeadLongitude(state, action) {
      state.addedLeadLongitude = action.payload;
    },
  },
});

export const {
  updateUserStatus,
  setLeadId,
  setLogActivity,
  setFollowUpHistory,
  setNoDataFound,
  setCreatedDate,
  setCompanyName,
  setIndustryName,
  setStateAndCity,
  setLeadType,
  setFollowUpLead,
  setLeadData,
  setEditLeadData,
  setStatus,
  setPosition,
  setMyLocation,
  setAddedLeadLatitude,
  setAddedLeadLongitude,
} = userStatusSlice.actions;

export default userStatusSlice.reducer;

export const getUserStatus = (userId, status, token) => async (dispatch) => {
  try {
    const response = await axiosPrivateCall.get(
      `/lead/getByUserIdAndStatus/${userId}/${status}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data.data;
    dispatch(updateUserStatus(data));
    dispatch(setNoDataFound(false)); // Reset noDataFound state on success
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.error("No data found:", error);
      dispatch(setNoDataFound(true)); // Set noDataFound state to true
    } else {
      console.error("Failed to fetch user status:", error);
    }
  }
};

export const filterLeads =
  (userId, status, cities, designations, token) => async (dispatch) => {
    try {
      const response = await axiosPrivateCall.post(
        `/lead/filter?cities=${cities}&designations=${designations}&userId=${userId}&status=${status}`,
        {
          userId,
          status,
          cities,
          designations,
        }
      );
      const data = response.data.data;
      dispatch(updateUserStatus(data));
    } catch (error) {
      console.error("Failed to filter leads:", error);
    }
  };

export const getLogActivity = (userId) => async (dispatch) => {
  try {
    const response = await axiosPrivateCall.get(
      `/logActivity/view/${userId}`
    );
    const data = response.data.data;
    dispatch(setLogActivity(data));
  } catch (error) {
    console.error("Failed to fetch log activity:", error);
  }
};

export const getFollowUpHistory = (userId) => async (dispatch) => {
  try {
    const response = await axiosPrivateCall.get(
      `/followUp/getFollowUpHistory?leadId=${userId}`
    );
    const data = response.data.data;
    dispatch(setFollowUpHistory(data));
  } catch (error) {
    console.error("Failed to fetch follow-up history:", error);
  }
};

export const bulkUpload = (userId, file) => async (dispatch) => {
  try {
    const response = await axiosPrivateCall.post(
      `/leads/upload/${userId}`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const data = response.data.data;
    dispatch(updateUserStatus(data));
  } catch (error) {
    console.error("Failed to bulk upload:", error);
  }
};

export const getIndustryType = () => async (dispatch) => {
  try {
    const response = await axiosPrivateCall.get(`/lead/getIndustryType`);
    const data = response.data.data;
    dispatch(setIndustryName(data));
  } catch (error) {
    console.error("Failed to fetch industry types:", error);
  }
};

export const getStateAndCity = () => async (dispatch) => {
  try {
    const response = await axiosPrivateCall.get(`/lead/getJson`);
    const data = response.data;
    dispatch(setStateAndCity(data));
  } catch (error) {
    console.error("Failed to fetch states and cities:", error);
  }
};

export const getLeadType = () => async (dispatch) => {
  try {
    const response = await axiosPrivateCall.get(`/lead/getLeadType`);
    const data = response.data.data;
    dispatch(setLeadType(data));
  } catch (error) {
    console.error("Failed to fetch lead types:", error);
  }
};

export const getFollowUpLead = (leadId) => async (dispatch) => {
  try {
    const response = await axiosPrivateCall.get(
      `/followUp/getFollowUpByLead/${leadId}`
    );
    const data = response.data.data;
    dispatch(setFollowUpLead(data));
  } catch (error) {
    console.error("Failed to fetch follow-up history:", error);
  }
};

export const getLeadData = (leadId) => async (dispatch) => {
  try {
    const response = await axiosPrivateCall.get(
      `/lead/getLeadById/${leadId}`
    );
    const data = response.data.data;
    dispatch(setLeadData(data));
  } catch (error) {
    console.error("Failed to fetch follow-up history:", error);
  }
};

export const addLeadInfo = (images, details) => async () => {
  const formData = new FormData();
  images.forEach((image, index) => {
    if (image) {
      formData.append(`images`, image);
    } else {
      console.log(`Image at index ${index} is not valid:`, image);
    }
  });

  formData.append("details", JSON.stringify(details));

  try {
    // after appended formData contents
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    const response = await axiosPrivateCall.post(
      "/LeadWeb/addLeadInfo",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    // errors that occur
    console.error("Error uploading images or details:", error);
  }
};

export const updateLeadInfo =
  (leadId, images, details, removeImageIds) => async (dispatch) => {
    const formData = new FormData();

    images.forEach((image, index) => {
      if (image instanceof File) {
        formData.append(`images`, image);
      }
    });

    formData.append("details", JSON.stringify(details));

    if (removeImageIds.length > 0) {
      formData.append("removeImageIds", removeImageIds);
    }

    try {
      const response = await axiosPrivateCall.put(
        `/editLeadInfo/updateLead/${leadId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      console.error("Error uploading images:", error);
      dispatch({ type: "UPDATE_LEAD_FAILURE", error });
    }
  };
