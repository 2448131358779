import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseRoundGray from "../../assets/svg/CloseRoundGray.svg";
import * as XLSX from "xlsx";
import AttachFile from "../../assets/svg/attachFile.svg";
import Download from "../../assets/svg/download.svg";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { bulkUpload } from "../../redux/Slice/userStatus";
import modalUpdated from "../../assets/svg/modalUpdated.svg";
import { SAMPLE_DATA } from "../../CommonMethods";
import Button from "../../commonComponents/Button";

const HiddenInput = styled("input")({
  display: "none",
});

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  border: "1px solid #ccc",
  padding: "8px",
  borderRadius: "4px",
  cursor: "pointer",
  backgroundColor: "#ffffff",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "16px",
    textAlign: "center",
  },
}));

const BulkUpload = ({ open, onClose, onSuccess }) => {
  // Added onSuccess prop
  const [fileSelected, setFileSelected] = useState(null);
  const [success, setSuccess] = useState(false);
  const loginUserId = useSelector(
    (state) => state.verification?.user?.id
  );
  const dispatch = useDispatch();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFileSelected(file);
  };

  const handleBulkUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", fileSelected);
      await dispatch(bulkUpload(loginUserId, formData)); // Await the dispatch
      setSuccess(true);
      setFileSelected(null);
      if (onSuccess) onSuccess(); // Call the onSuccess callback
      setTimeout(() => {
        setSuccess(false); // Reset success after a delay
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadTemplate = () => {
    const worksheet = XLSX.utils.json_to_sheet(SAMPLE_DATA);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");
    XLSX.writeFile(workbook, "Sample_Template.xlsx");
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ backdropFilter: "blur(2px)", backgroundColor: "rgba(0,0,0,0.25)" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          boxShadow: 24,
          p: 2.5,
          minWidth: { xs: 300, sm: 400, md: 500 }, // Responsive width
          maxWidth: { xs: "90%", md: 600 },
          maxHeight: "80%",
          overflowY: "auto",
          borderRadius: 1.5,
        }}
      >
        {!success ? (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                textAlign: { xs: "center", sm: "left" },
              }} // Responsive layout
            >
              <Typography variant="h6">{"Bulk Upload"}</Typography>
            </Box>
            <label htmlFor="file-upload">
              <CustomBox>
                <img src={AttachFile} alt="Attach file" />
                <Typography
                  sx={{
                    ml: 1.3,
                    mt: 0.2,
                    color: "#505259",
                    fontSize: { xs: "14px" },
                  }} // Responsive font size
                  variant="body2"
                >
                  {fileSelected ? fileSelected.name : "Add attachment"}
                </Typography>
                <HiddenInput
                  id="file-upload"
                  type="file"
                  onChange={handleFileUpload}
                />
              </CustomBox>
            </label>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: 10,
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left",
                mb: 2,
                mt: { xs: 2, sm: 0 }, // Add margin on small screens
              }}
            >
              {"Supported formats: .xls, .xlsx"}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              mb={2}
              onClick={handleDownloadTemplate}
              sx={{
                cursor: "pointer",
                justifyContent: { xs: "center", sm: "flex-start" },
              }} // Center on small screens
            >
              <img src={Download} alt={"Download"} />
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: "14.06px",
                  textAlign: "left",
                  ml: 1.2,
                  color: "#707070",
                  textDecoration: "underline",
                }}
              >
                {"Download Sample Template"}
              </Typography>
            </Box>
            <IconButton
              onClick={onClose}
              size="small"
              sx={{ position: "absolute", top: 8, right: 8 }}
            >
              <img src={CloseRoundGray} alt={"Close Round Gray"} />
            </IconButton>
            <Box
              mt={4}
              display="flex"
              justifyContent="flex-end"
              gap={1}
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "center", sm: "flex-end" },
              }}
            >
              <Button
                px={3}
                text={"Cancel"}
                color={"#E7554A"}
                borderColor={"#E7554A"}
                background={"#FCE9E8"}
                variant={"outlined"}
                onClick={onClose}
                width={{ xs: "100%", sm: "auto" }}
              />
              <Button
                px={3}
                text={"Update"}
                color={"#ffffff"}
                borderColor={"#3771C3)"}
                background={"var(--gradient1)"}
                variant={"outlined"}
                disabled={!fileSelected}
                onClick={handleBulkUpload}
                mt={{ xs: 1, sm: 0 }}
                width={{ xs: "100%", sm: "auto" }}
              />
            </Box>
          </>
        ) : (
          <Box sx={{ lineHeight: 3, my: 9 }}>
            <Box
              sx={{
                position: "absolute",
                top: 12,
                right: 15,
                cursor: "pointer",
              }}
            >
              <img src={CloseRoundGray} alt={"close icon"} onClick={onClose} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img src={modalUpdated} alt={"updated success icon"} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <h4>Updated Successfully</h4>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default BulkUpload;
