import { IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { SEARCH_TEXT_FIELD, ICON_WRAPPER_SEARCH } from "../../CommonMethods";
import SearchIcon from "../../assets/svg/searchIcon.svg";
import CloseRoundIcon from "../../assets/svg/closeRound.svg";

const TypeSearch = ({ placeholder, onSearch }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (onSearch) {
      onSearch(value); // Trigger the search callback if provided
    }
  };

  const handleClear = () => {
    setSearchValue("");
    if (onSearch) {
      onSearch(""); // Clear the search results if onSearch is provided
    }
  };

  return (
    <SEARCH_TEXT_FIELD
      size="small"
      variant="outlined"
      value={searchValue}
      onChange={handleChange}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <ICON_WRAPPER_SEARCH position="start">
            <img src={SearchIcon} alt={"Search Icon"} />
          </ICON_WRAPPER_SEARCH>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{ display: searchValue ? "flex" : "none", cursor: "pointer" }}
            onClick={handleClear}
          >
            <IconButton>
              <img src={CloseRoundIcon} alt={"Close Round Icon"} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default TypeSearch;
