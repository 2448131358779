import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  dashData: null,
  dashError: null
};

const boardData = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setDashData: (state, action) => {
      state.dashData = action.payload;
    },
    setDashDataError:(state, action) =>{
        state.dashError = action.payload;
    }
  },
});

export const { setDashData, setDashDataError } = boardData.actions;
export default boardData.reducer;