import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloseRoundIcon from "../../assets/svg/closeRound.svg";
import CloseRoundRed from "../../assets/svg/closeRoundRed.svg";
import ExpandMoreIcon from "../../assets/svg/expandMoreIcon.svg";
import FilterIcon from "../../assets/svg/filterIcon.svg";
import FilterIconGray from "../../assets/svg/filterIconGray.svg";
import SearchIconGray from "../../assets/svg/searchIconGray.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTERED_SCROLLABLE_BOX,
  FILTER_TEXT_FIELD,
  SCROLLABLE_BOX,
  SORT_AND_FILTER_BUTTON,
} from "../../CommonMethods";
import { filterLeads } from "../../redux/Slice/userStatus";

const ICON_WRAPPER = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: 8,
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
  },
}));

const ButtonText = styled("span")(({ theme }) => ({
  flexGrow: 1,
  textAlign: "center",
  color: "#17437f",
  [theme.breakpoints.down("sm")]: {
    // fontSize: "12px",
  },
}));

const CustomTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "24px",
  textAlign: "left",
  color: "var(--secondary)",
});

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#D1D1D1",
  height: "12px",
  width: "12px",
  margin: 0,
  marginRight: "5%",
  [theme.breakpoints.down("sm")]: {
    height: "10px",
    width: "10px",
  },
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "0px",
  letterSpacing: "0.02em",
  textAlign: "left",
  color: "#44464C",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

const SortAndFilter = ({ setClearAllTrigger }) => {
  const dispatch = useDispatch();
  const userStatus =
    useSelector((state) => state.userStatusData.userStatus) || [];
  const [open, setOpen] = useState(false);
  const [locationFilterOpen, setLocationFilterOpen] = useState(true);
  const [designationFilterOpen, setDesignationFilterOpen] = useState(false);
  const [searchLocation, setSearchLocation] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [searchDesignation, setSearchDesignation] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState([]);
  const token = useSelector((state) => state.verification?.token);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLocationClick = () => {
    setLocationFilterOpen(true);
    setDesignationFilterOpen(false);
  };

  const handleDesignationClick = () => {
    setLocationFilterOpen(false);
    setDesignationFilterOpen(true);
  };

  const handleLocationChange = (event) => {
    const { value } = event.target;
    setSearchLocation(value);
  };

  const handleDesignationChange = (event) => {
    const { value } = event.target;
    setSearchDesignation(value);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation((prev) =>
      prev.includes(location)
        ? prev.filter((loc) => loc !== location)
        : [...prev, location]
    );
  };

  const handleDesignationSelect = (designation) => {
    setSelectedDesignation((prev) =>
      prev.includes(designation)
        ? prev.filter((des) => des !== designation)
        : [...prev, designation]
    );
  };

  const handleClearAll = () => {
    console.log("Clearing all filters");
    setSelectedLocation([]);
    setSelectedDesignation([]);
    setClearAllTrigger((prev) => {
      const newValue = !prev;
      console.log("New clear all trigger value:", newValue);
      return newValue;
    });
  };

  const locations = Array.from(
    new Set(userStatus?.map((user) => user?.city).filter(Boolean) || [])
  );
  const designations = Array.from(
    new Set(
      userStatus?.flatMap(
        (user) =>
          user?.contactPerson
            ?.map((person) => person?.designation)
            .filter(Boolean) || []
      ) || []
    )
  );

  const userId = [
    ...new Set(userStatus?.map((el) => el.userId).filter(Boolean)),
  ].join();
  const status = [
    ...new Set(userStatus?.map((el) => el.status).filter(Boolean)),
  ].join();

  const filteredLocations = locations.filter((loc) =>
    loc.toLowerCase().includes(searchLocation.toLowerCase())
  );
  const filteredDesignations = designations.filter((des) =>
    des.toLowerCase().includes(searchDesignation.toLowerCase())
  );

  const getSelectedFiltersCount = () => {
    return selectedLocation.length + selectedDesignation.length;
  };

  const handleApplyFilters = () => {
    dispatch(
      filterLeads(userId, status, selectedLocation, selectedDesignation, token)
    );
    handleClose();
  };

  return (
    <>
      <SORT_AND_FILTER_BUTTON onClick={handleOpen}>
        <ICON_WRAPPER>
          <img src={FilterIcon} alt="Filter Icon" />
        </ICON_WRAPPER>
        <ButtonText>Sort and Filters</ButtonText>
        <InputAdornment position="end">
          <img src={ExpandMoreIcon} alt="Expand more icon" />
        </InputAdornment>
      </SORT_AND_FILTER_BUTTON>

      <Modal
        sx={{
          backdropFilter: "blur(2px)",
          backgroundColor: "rgba(0,0,0,0.25)",
        }}
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            position: "absolute",
            top: "25%",
            left: isMobile ? "" : "17%",
            p: isMobile ? 2.5 : 4,
            bgcolor: "background.paper",
            margin: "auto",
            width: isMobile ? "90%" : "60%",

            borderRadius: 1.5,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3.8}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <img src={FilterIconGray} alt="Filter Icon" />
                <CustomTypography>Sort and Filters</CustomTypography>
                <img
                  className={"pointer"}
                  src={CloseRoundIcon}
                  alt="Close Round Icon"
                  onClick={handleClearAll}
                />
              </Box>
              <Divider
                style={{ marginBottom: "2%" }}
                orientation="horizontal"
                flexItem
              />
              <CustomTypography
                sx={{
                  lineHeight: 3,
                  color: locationFilterOpen ? "#17437f" : "",
                  cursor: "pointer",
                }}
                onClick={handleLocationClick}
              >
                Location
              </CustomTypography>
              <CustomTypography
                sx={{
                  color: designationFilterOpen ? "#17437f" : "",
                  cursor: "pointer",
                }}
                onClick={handleDesignationClick}
              >
                Designation
              </CustomTypography>
            </Grid>

            <Divider
              style={{ marginLeft: "1%" }}
              orientation="vertical"
              flexItem
            />

            {locationFilterOpen ? (
              <Grid item xs={12} sm={3.8}>
                <CustomTypography>{"Location Filters"}</CustomTypography>
                <Divider
                  style={{ marginBottom: "4%" }}
                  orientation="horizontal"
                  flexItem
                />
                <FILTER_TEXT_FIELD
                  fullWidth
                  placeholder="Search Location"
                  value={searchLocation}
                  onChange={handleLocationChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          height={22}
                          width={22}
                          src={SearchIconGray}
                          alt={"Search Icon"}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <SCROLLABLE_BOX>
                  <List>
                    {filteredLocations.map((location) => {
                      const isChecked = selectedLocation.includes(location);
                      return (
                        <ListItem
                          key={location}
                          onClick={() => handleLocationSelect(location)}
                        >
                          <CustomCheckbox
                            style={{ color: isChecked ? "#17437f" : "" }}
                            checked={isChecked}
                          />
                          <CustomListItemText primary={location} />
                        </ListItem>
                      );
                    })}
                  </List>
                </SCROLLABLE_BOX>
              </Grid>
            ) : (
              <Grid item xs={12} sm={3.8}>
                <CustomTypography>{"Designation Filters"}</CustomTypography>
                <Divider
                  style={{ marginBottom: "4%" }}
                  orientation="horizontal"
                  flexItem
                />
                <FILTER_TEXT_FIELD
                  fullWidth
                  placeholder="Search Designation"
                  value={searchDesignation}
                  onChange={handleDesignationChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          height={22}
                          width={22}
                          src={SearchIconGray}
                          alt={"Search Icon"}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <SCROLLABLE_BOX>
                  <List>
                    {filteredDesignations.map((designation) => {
                      const isChecked =
                        selectedDesignation.includes(designation);
                      return (
                        <ListItem
                          key={designation}
                          onClick={() => handleDesignationSelect(designation)}
                        >
                          <CustomCheckbox
                            style={{ color: isChecked ? "#17437f" : "" }}
                            checked={isChecked}
                          />
                          <CustomListItemText primary={designation} />
                        </ListItem>
                      );
                    })}
                  </List>
                </SCROLLABLE_BOX>
              </Grid>
            )}

            {(locationFilterOpen && (
              <Divider
                style={{ marginLeft: "1.8%" }}
                orientation="vertical"
                flexItem
              />
            )) ||
              (designationFilterOpen && (
                <Divider
                  style={{ marginLeft: "1.8%" }}
                  orientation="vertical"
                  flexItem
                />
              ))}

            {(selectedLocation.length > 0 ||
              selectedDesignation.length > 0) && (
              <Grid item xs={12} sm={3.8}>
                <Box display="flex" justifyContent="space-between">
                  <Typography color={"var(--gradient1)"}>
                    {getSelectedFiltersCount()} Filter
                    {getSelectedFiltersCount() > 1 ? "s" : ""} Selected
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={handleClearAll}
                  >
                    <img
                      height={20}
                      width={20}
                      src={CloseRoundRed}
                      alt={"Close Round Red"}
                    />
                    <Typography sx={{ color: "red", ml: 0.5 }}>
                      Clear All
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  style={{ marginBottom: "2%" }}
                  orientation="horizontal"
                  flexItem
                />
                <FILTERED_SCROLLABLE_BOX>
                  {selectedLocation.map((location) => (
                    <Box key={location} display="flex" alignItems="center">
                      <Checkbox style={{ color: "#17437f" }} checked={true} />
                      <Typography>{location}</Typography>
                    </Box>
                  ))}
                  {selectedDesignation.map((designation) => (
                    <Box key={designation} display="flex" alignItems="center">
                      <Checkbox style={{ color: "#17437f" }} checked={true} />
                      <Typography>{designation}</Typography>
                    </Box>
                  ))}
                </FILTERED_SCROLLABLE_BOX>
              </Grid>
            )}
          </Grid>
          {(locationFilterOpen && (
            <Box mt={4} display={"flex"} justifyContent="flex-end" gap={1}>
              <Button
                size={"small"}
                sx={{ px: 7, color: "red", borderColor: "red" }}
                variant={"outlined"}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                size={"small"}
                sx={{ px: 7, background: "var(--gradient1)" }}
                variant={"contained"}
                onClick={handleApplyFilters}
                disabled={
                  !(
                    selectedLocation.length > 0 ||
                    selectedDesignation.length > 0
                  )
                }
              >
                Apply
              </Button>
            </Box>
          )) ||
            (designationFilterOpen && (
              <Box mt={4} display="flex" justifyContent="flex-end" gap={1}>
                <Button
                  size={"small"}
                  sx={{ px: 7, color: "red", borderColor: "red" }}
                  variant={"outlined"}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  size={"small"}
                  sx={{ px: 7, background: "var(--gradient1)" }}
                  variant={"contained"}
                  onClick={handleApplyFilters}
                  disabled={
                    !(
                      selectedLocation.length > 0 ||
                      selectedDesignation.length > 0
                    )
                  }
                >
                  Apply
                </Button>
              </Box>
            ))}
        </Box>
      </Modal>
    </>
  );
};

export default SortAndFilter;
