import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import logos from "../../assets/svg/loginBudgie.svg";
import WindowIcon from "../../assets/svg/microsoftLogo.svg";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../redux/Slice/authSlice";
import { msData } from "./Validation/UserValidation";
import { prod } from "../../utilis/Rauth/Portauth";
import MyLocation from "../../components/MyLocation";

const Login = () => {
  const [loading, setLoading] = useState(false); // Added loading state
  const [locationAllowed, setLocationAllowed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMicrosoftSignIn = () => {
    if (locationAllowed) {
      window.location.href = prod;
    } else {
      alert("Please allow location access to sign in");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const email = urlParams.get("email");
    const name = decodeURIComponent(urlParams.get("name"));
    const designation = decodeURIComponent(urlParams.get("designation"));
    const contactNum = decodeURIComponent(urlParams.get("mobile"));
    if (token) {
      setLoading(true);
      msData(email, name, designation, contactNum)
        .then((res) => {
          if (res.token) {
            dispatch(setToken(res.token));
            navigate("/home/dashboard");
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          navigate("/");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
      container
      spacing={1}
      columns={16}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-around",
        }}
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        xl={8}
      >
        <div>
          <img src={logos} alt="Logo" />
        </div>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-around",
        }}
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        xl={8}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "22px",
              lineHeight: "42px",
            }}
          >
            Welcome
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "32.5px",
              color: "#34353A",
            }}
          >
            Sign in to continue
          </Typography>
          <div
            style={{
              color: "#28292D",
              border: "1.4px solid #DCDCDE ",
              fontSize: "12px",
              borderRadius: "8px",
              fontWeight: 600,
              marginBottom: "26px",
              marginTop: "26px",
            }}
            onClick={handleMicrosoftSignIn}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                py: "1vh",
                px: "6vw",
                gap: 1.5,
              }}
            >
              <img src={WindowIcon} alt="Microsoft Logo" />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "32.5px",
                  color: "#34353A",
                  cursor: "pointer",
                }}
              >
                Sign in with Microsoft
              </Typography>
            </Box>
          </div>
          <Typography
            sx={{
              fontWeight: 300,
              fontSize: "11px",
              lineHeight: "26px",
              color: "#2C5192",
            }}
          >
            <p>Copyright © 2024 CITPL. All rights reserved.</p>
            <p>Designed & Developed By CITPL</p>
          </Typography>
        </Box>
      </Grid>
      <MyLocation onLocationAllowed={setLocationAllowed} />
    </Grid>
  );
};

export default Login;
