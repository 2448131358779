import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

// Common button styles
const commonButtonStyles = {
  width: "30%",
  height: "7vh",
  fontSize: "14px",
  fontWeight: 500,
  textTransform: "none",
};

const ApplyStyledButton = styled(Button)({
  ...commonButtonStyles,
  borderColor: "#DCDCDE", // Border color
  color: "white", // Text color
  background: "linear-gradient(#0B3670, #3665A4)", // Background gradient
  "&:hover": {
    background: "linear-gradient(#0B3670, #3665A4)", // Ensure hover is similar
  },
});

export const ApplyButton = ({ onApply }) => {
  return (
    <ApplyStyledButton variant="outlined" onClick={onApply}>
      Apply
    </ApplyStyledButton>
  );
};

const CancelStyledButton = styled(Button)({
  ...commonButtonStyles,
  backgroundColor: "#FDF4F3", // Background color
  borderColor: "#E7554A", // Border color
  color: "#E7554A", // Text color
  "&:hover": {
    borderColor: "#E7554A", // Border on hover
  },
});

export const CancelButton = ({ close }) => {
  return (
    <CancelStyledButton variant="outlined" onClick={close}>
      Cancel
    </CancelStyledButton>
  );
};
