import React from "react";
import styles from "./card.module.css";
import priceAdd from "../../assets/svg/Vector.svg";
import people from "../../assets/svg/people.svg";
import Target from "../../assets/svg/Target.svg";
import lineup from "../../assets/svg/chart-line-up.svg";
import linedown from "../../assets/svg/chart-line-down.svg";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { formatIndianNumber } from "../../CommonMethods";
import Card from "@mui/material/Card";
import TruncatedText from "../../commonComponents/TruncatedText";

export const EstimatedRevenueCard = ({ revenue }) => {
  return (
    <div className={styles.cardCont}>
      <Card
        className={styles.cardOrgin}
        sx={{
          borderRadius: "10px",
          border: "1px solid #ffffff",
        }}
      >
        <div className={styles.headsection1}>
          <span>Estimate Revenue</span>
          <img alt="" src={priceAdd} />
        </div>
        <div className={styles.headsection3}>
          <CurrencyRupeeIcon sx={{ height: "20px" }} />
          <div className="scrollbar-hidden" style={{ overflowX: "auto" }}>
            <TruncatedText
              text={
                revenue?.estimateRevenue
                  ? formatIndianNumber(revenue?.estimateRevenue)
                  : 0
              }
              maxLength={14}
            />
            {/* <span>
              {revenue?.estimateRevenue
                ? formatIndianNumber(revenue?.estimateRevenue)
                : 0}
            </span> */}
          </div>
        </div>
      </Card>
    </div>
  );
};
export const MeetCard = ({ totalMeet }) => {
  return (
    <div className={styles.cardCont}>
      <Card
        className={styles.cardOrgin}
        sx={{
          borderRadius: "10px",
          border: "1px solid #ffffff",
        }}
      >
        <div className={styles.headsection1}>
          <span>Total Meeting</span>
          <img alt="" src={people} />
        </div>
        <div className={styles.headsection4}>
          <span>
            {totalMeet?.finishedMeeting ? totalMeet?.finishedMeeting : 0}
          </span>
          <span>/</span>
          <span>
            {totalMeet?.totNumOfMeeting ? totalMeet?.totNumOfMeeting : 0}
          </span>
        </div>
      </Card>
    </div>
  );
};
export const TargetCard = ({ target }) => {
  return (
    <div className={styles.cardCont}>
      <Card
        className={styles.cardOrgin}
        sx={{
          borderRadius: "10px",
          border: "1px solid #ffffff",
        }}
      >
        <div className={styles.headsection1}>
          <span>Target</span>
          <img alt="" src={Target} />
        </div>
        <div className={styles.headsection2}>
          <span style={{ color: "#000000", fontWeight: "600" }}>{`${
            target?.target ? target?.target : 0
          } Clients`}</span>
          <span>{`0 Cr`}</span>
        </div>
      </Card>
    </div>
  );
};
export const WonCard = ({ won }) => {
  return (
    <div className={styles.cardCont}>
      <Card
        className={styles.cardOrgin}
        sx={{
          backgroundColor: "#E0F1E6",
          border: "1px solid #13BA50",
          borderRadius: "10px",
        }}
      >
        <div className={styles.headsection1}>
          <span>Won</span>
          <img alt="" src={lineup} />
        </div>
        <div className={styles.headsection2}>
          <span style={{ color: "green" }}>{`${
            won?.won ? won?.won : 0
          } Clients`}</span>
          <span>{`${
            won?.estimateRevenueBYClient ? won?.estimateRevenueBYClient : 0
          } Cr`}</span>
        </div>
      </Card>
    </div>
  );
};
export const LossCard = ({ lost }) => {
  return (
    <div className={styles.cardCont}>
      <Card
        className={styles.cardOrgin}
        sx={{
          backgroundColor: "#F8E2E2",
          border: "1px solid #FF2727",
          borderRadius: "10px",
        }}
      >
        <div className={styles.headsection1}>
          <span>Lost</span>
          <img alt="" src={linedown} />
        </div>
        <div className={styles.headsection2}>
          <span style={{ color: "red" }}>{`${
            lost?.lost ? lost?.lost : 0
          } Clients`}</span>
          <span>{`0 Cr`}</span>
        </div>
      </Card>
    </div>
  );
};
