import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setFromYear, setToYear } from '../../../redux/Slice/timeSlice.js';

const YearDropdown = ({ type, selectedYear, setSelectedYear }) => {
  const years = Array.from(new Array(100), (val, index) => new Date().getFullYear() - index);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <FormControl fullWidth variant="outlined" style={{ margin: '20px 0', width: '100px' }}>
      <InputLabel id="year-label">Year</InputLabel>
      <Select
        sx={{ height: '8vh' }}
        labelId="year-label"
        id="year-select"
        value={selectedYear}
        onChange={handleChange}
        label={type === 'from' ? 'From Year' : 'To Year'}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
      >
        {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


export default YearDropdown;
