import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { PieChart } from "@mui/x-charts/PieChart";
import React from "react";
import styles from "./chart.module.css";
import { useDynamicHeight } from "../../hooks/useDynamicHeight";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            "& .MuiLinearProgress-bar": {
              backgroundColor: props.bar,
            },
            "&.MuiLinearProgress-colorPrimary": {
              backgroundColor: "#B3B3B3",
              borderRadius: "5px",
              height: "5px",
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="#222222"
          sx={{ fontWeight: "700" }}
        >{`${Math.round(props.value)}`}</Typography>
      </Box>
    </Box>
  );
}

export const ProgressBar = ({ lin }) => {
  return (
    <div className={styles.linearchart}>
      <LinearProgressWithLabel
        value={lin ? lin?.numOfHot : 0}
        bar={"#62CBFA"}
      />
      <LinearProgressWithLabel
        value={lin ? lin?.numOfCold : 0}
        bar={"#FCAF3C"}
      />
      <LinearProgressWithLabel
        value={lin ? lin?.numOfWarm : 0}
        bar={"#FC8686"}
      />
      <LinearProgressWithLabel
        value={lin ? lin?.numOfAgreement : 0}
        bar={"#13BA50"}
      />
      <LinearProgressWithLabel
        value={lin ? lin?.numOfClients : 0}
        bar={"#1356BA"}
      />
    </div>
  );
};

export const PipelineChart = ({ funl }) => {
  const screenSize = useDynamicHeight();
  const progressBarHeight = screenSize.dynamicHeight * 0.526;

  const pieChartData = [
    {
      label: "Hot",
      value: funl ? funl?.numOfHot : 1,
      color: "#FC8686",
      amount: funl ? funl?.estimateRevenueBYHot : 1,
    },
    {
      label: "Cold",
      value: funl ? funl?.numOfCold : 1,
      color: "#62CBFA",
      amount: funl ? funl?.estimateRevenueBYCold : 1,
    },
    {
      label: "Warm",
      value: funl ? funl?.numOfWarm : 1,
      color: "#FCAF3C",
      amount: funl ? funl?.estimateRevenueBYWarm : 1,
    },
    {
      label: "Agreement",
      value: funl ? funl?.numOfAgreement : 1,
      color: "#13BA50",
      amount: funl ? funl?.estimateRevenueBYAgreement : 1,
    },
    {
      label: "Client",
      value: funl ? funl?.numOfClients : 1,
      color: "#1356BA",
      amount: funl ? funl?.estimateRevenueBYClient : 1,
    },
  ];

  return (
    <>
      <PieChart
        series={[
          {
            data: pieChartData,
            innerRadius: 80,
            outerRadius: 150,
            cornerRadius: 5,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 60, additionalRadius: -30, color: "#B3B3B3" },
            valueFormatter: (v, { dataIndex }) => {
              const { amount } = pieChartData[dataIndex];
              return `Leads: ${v.value}, Amount: ${amount}`;
            },
          },
        ]}
        height={progressBarHeight}
        slotProps={{
          legend: { hidden: false },
        }}
        tooltip={{ trigger: "item" }} // if trigger:none will close the tooltip
      />
    </>
  );
};

export const Barchart = ({ prospectData, clientData, xLabels }) => {
  const screenSize = useDynamicHeight();
  const barChartWidth = screenSize.dynamicWidth * 0.46;
  const barChartHeight = screenSize.dynamicHeight * 0.606;
  return (
    <div>
      <BarChart
        width={barChartWidth}
        height={barChartHeight}
        series={[
          {
            data: prospectData,
            label: "Prospect",
            id: "pvId",
            color: "#357AF6",
          },
          { data: clientData, label: "Client", id: "uvId", color: "#5CC8BE" },
        ]}
        xAxis={[
          {
            data: xLabels,
            scaleType: "band",
            categoryGapRatio: 0.3,
            barGapRatio: 0.3,
          },
        ]}
        borderRadius={10}
        slotProps={{
          legend: {
            hidden: true,
            markGap: 5,
            itemGap: 10,
            direction: "row",
            position: { vertical: "top", horizontal: "right" },
            padding: 0,
          },
        }}
      />
    </div>
  );
};

export const SparkChart = ({ prospectData, clientData, xLabels }) => {
  const screenSize = useDynamicHeight();
  const sparkChartWidth = screenSize.dynamicWidth * 0.46;
  const sparkChartHeight = screenSize.dynamicHeight * 0.606;

  return (
    <>
      <div>
        <LineChart
          width={sparkChartWidth}
          height={sparkChartHeight}
          series={[
            {
              curve: "linear",
              data: prospectData,
              label: "Target",
              color: "#357AF6",
            },
            {
              curve: "linear",
              data: clientData,
              label: "Achieved",
              color: "#5CC8BE",
            },
          ]}
          xAxis={[{ scaleType: "point", data: xLabels }]}
          slotProps={{
            legend: {
              hidden: true,
              markGap: 5,
              itemGap: 10,
              direction: "row",
              position: { vertical: "top", horizontal: "right" },
              padding: 0,
            },
          }}
        />
      </div>
    </>
  );
};
