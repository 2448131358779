import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Spinner from "../../components/Spinner";
import { useDynamicHeight } from "../../hooks/useDynamicHeight";

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "centerAlign",
})(({ theme, centerAlign }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEF5FC",
    color: "#44464C",
    fontSize: 14,
    fontWeight: 600,
    padding: "13px 5px 13px 10px",
    border: "none",
    textAlign: centerAlign ? "center" : "left",
    zIndex: 3,
    position: "sticky",
    top: "0px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#FFFFFF",
    lineHeight: "16.41px",
    border: "0px, 0px, 1px, 0px",
    padding: "10px",
    textAlign: centerAlign ? "center" : "left",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableComponent = ({
  tableDataSet,
  displayDataSet,
  tableOnClick,
  loading,
  isStatus,
}) => {
  const screenSize = useDynamicHeight();
  const tableHeightActive = screenSize.dynamicHeight * 0.425;
  const tableHeightDraft = screenSize.dynamicHeight * 0.516;
  const containerHeight =
    isStatus === "draft"
      ? tableHeightDraft
      : isStatus === "active"
      ? tableHeightActive
      : "0px";

  return (
    <TableContainer
      component={Paper}
      className={"custom-scrollbar custom-scrollbarX"}
      sx={{ maxHeight: containerHeight, overflowY: "auto" }}
    >
      <Table
        sx={{ minWidth: 700, border: "none" }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            {Object.keys(displayDataSet[0]).map((key, index) =>
              key !== "id" &&
              key !== "createdDate" &&
              key !== "companyName" &&
              key !== "lat" &&
              key !== "lon" ? (
                <StyledTableCell key={index} centerAlign={key === "Type"}>
                  {key}
                </StyledTableCell>
              ) : null
            )}
          </TableRow>
        </TableHead>
        <TableBody sx={{ cursor: "pointer" }}>
          {loading ? (
            <TableRow>
              <TableCell
                colSpan={Object.keys(displayDataSet[0]).length}
                align="center"
              >
                <Spinner />
              </TableCell>
            </TableRow>
          ) : (
            displayDataSet.map((row, index) => (
              <StyledTableRow
                key={index}
                onClick={(e) => tableOnClick(e, index, row)}
              >
                {Object.keys(row).map((key, subIndex) =>
                  key !== "id" &&
                  key !== "createdDate" &&
                  key !== "companyName" &&
                  key !== "lat" &&
                  key !== "lon" ? (
                    <StyledTableCell
                      key={subIndex}
                      centerAlign={key === "Type"}
                    >
                      {row[key]}
                    </StyledTableCell>
                  ) : null
                )}
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
