import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    width: '100%',
    padding: '2px 4px',
    borderRadius: '4px',
    border: '1px solid #DCDCDE',
    backgroundColor: '#EEF5FC',
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    width: '50%',
    height: '24px',
    padding: '0px 12px',
    gap: '0px',
    borderRadius: '4px',
    background: '#EEF5FC',
    boxShadow: '0px 3px 8px 0px rgba(66, 33, 109, 0.25)',
    color: '#27538D',
    textTransform: 'none',
    '&.Mui-selected': {
        backgroundColor: '#27538D',
        color: 'white',
        '&:hover': {
            backgroundColor: '#27538D',
        },
    },
    '&:hover': {
        backgroundColor: '#EEF5FC',
    },
}));

const CustomToggleButton = ({ defaultValue, buttons, onButtonClick }) => {
    const [alignment, setAlignment] = React.useState(defaultValue);

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const handleButtonClick = (value) => {
        if (onButtonClick) {
            onButtonClick(value);
        }
    };

    return (
        <Box display="flex" justifyContent="center">
            <StyledToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Toggle Button Group"
            >
                {buttons.map((button) => (
                    <StyledToggleButton
                        key={button.value}
                        value={button.value}
                        selected={alignment === button.value}
                        onClick={() => handleButtonClick(button.value)}
                    >
                        {button.label}
                    </StyledToggleButton>
                ))}
            </StyledToggleButtonGroup>
        </Box>
    );
};

CustomToggleButton.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    onButtonClick: PropTypes.func, // PropTypes for onClick handler
};

export default CustomToggleButton;
