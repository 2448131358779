import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFromMonth, setToMonth } from '../../../redux/Slice/timeSlice.js';

const MonthDropdown = ({ type, selectedMonth, setSelectedMonth }) => {
  const months = [
    "Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"
  ];

  const handleChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <FormControl fullWidth variant="outlined" style={{ margin: '20px 0', width: '100px' }}>
      <InputLabel id="month-label">Month</InputLabel>
      <Select
        sx={{height:'8vh'}}
        labelId="month-label"
        id="month-select"
        value={selectedMonth}
        onChange={handleChange}
        label={type === 'from' ? 'From Month' : 'To Month'}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
      >
        {months.map((month, index) => (
          <MenuItem key={month} value={index}>
            {month}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


export default MonthDropdown;
