import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import YearDropdown from "../Dropdown/Yeardropdown/Yeardrop";
import MonthDropdown from "../Dropdown/Monthdropdown/Monthdrop";
import { ApplyButton, CancelButton } from "../Buttonscomp/CustomButton";
import { useSelector } from "react-redux";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

export default function TimeModal({ open, handleClose, apply }) {
  const dateValues = useSelector((state) => state.date);
  const { from, to } = dateValues;

  const [fromMonth, setFromMonth] = useState(from.month);
  const [fromYear, setFromYear] = useState(from.year);
  const [toMonth, setToMonth] = useState(to.month);
  const [toYear, setToYear] = useState(to.year);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleApply = () => {
    apply({ fromMonth, fromYear, toMonth, toYear });
    handleClose();
  };

  const style = {
    position: "absolute",
    top: isSmallScreen ? "50%" : "46%",
    left: isSmallScreen ? "50%" : "22%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: 0,
    borderRadius: "5px",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    ml: isSmallScreen ? "" : 6,
    width: "90%", // Adjust width for smaller screens
    maxWidth: "440px", // Limit width on larger screens
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgba(0,0,0,0.11)",
        }}
      >
        <Box sx={style}>
          <Grid
            container
            spacing={isSmallScreen ? 2 : 2}
            justifyContent="center"
          >
            {/* From Section */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ mb: 0, fontWeight: 500 }}>From</Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <MonthDropdown
                  type="from"
                  selectedMonth={fromMonth}
                  setSelectedMonth={setFromMonth}
                />
                <YearDropdown
                  type="from"
                  selectedYear={fromYear}
                  setSelectedYear={setFromYear}
                />
              </Box>
            </Grid>
            {/* To Section */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ mb: 0, fontWeight: 500 }}>To</Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <MonthDropdown
                  type="to"
                  selectedMonth={toMonth}
                  setSelectedMonth={setToMonth}
                />
                <YearDropdown
                  type="to"
                  selectedYear={toYear}
                  setSelectedYear={setToYear}
                />
              </Box>
            </Grid>
          </Grid>
          {/* Apply Button Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              gap: "10px",
            }}
          >
            <CancelButton close={handleClose} />
            <ApplyButton onApply={handleApply} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
